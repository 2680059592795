import React, { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { SERVICEIMAGE } from "../api/config/Config";

const ImageUser = React.memo(({ datas, className, width, height, size }) => {
  const [data, setData] = useState(datas);

  const checkImageCrash = (e) => {
    setData((prevData) => ({
      ...prevData,
      is_crash: true,
    }));
  };

  const manipData = () => {
    if (datas) {
      let temp = { ...datas };
      if (temp?.user) {
        temp.name = [datas?.user?.first_name, datas?.user?.last_name].join(" ");
        temp.image = datas?.user?.image;
        temp.is_crash = datas?.is_crash;
      } else {
        temp.name = datas?.full_name
          ? datas?.full_name
          : datas?.name
          ? datas?.name
          : [datas?.first_name, datas?.last_name].join(" ");
        temp.image = datas.image
          ? datas.image?.includes("/storage/")
            ? datas.image
            : `/storage/${datas.image}`
          : null;
        temp.is_crash = datas?.is_crash;
      }
      setData(temp);
    }
  };

  useEffect(() => {
    manipData();
    //eslint-disable-next-line
  }, [datas]);

  return data?.image ? (
    data?.is_crash ? (
      <Avatar name={data?.name} round size={40} className={className} />
    ) : (
      <img
        src={SERVICEIMAGE + data?.image}
        alt="avatar"
        height={height || 38}
        width={width || 38}
        style={{ objectFit: "cover" }}
        className={`${className} rounded-circle`}
        onError={checkImageCrash}
      />
    )
  ) : (
    <Avatar name={data?.name} round size={40} className={className} />
  );
});

export default React.memo(ImageUser, (prevProps, nextProps) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
});
