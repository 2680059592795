import React from "react";

import Loading from "../../../utility/Loading";
class SpinnerComponent extends React.Component {
  render() {
    return <Loading />;
  }
}

export default SpinnerComponent;
