import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { history } from "../history";
import { logout } from "../api/controller/AuthController";
import { AsyncStorage } from "reactjs-async-localstorage";

export const ModalMovePage = ({
  modal,
  setModal,
  action,
  cancel,
  move,
  toggleMove,
  path,
  data,
  tab,
}) => {
  const handleLogout = async () => {
    let log = await logout();
    if (log) {
      window.location.href = "/";
    }
  };

  const removeStorage = async () => {
    await AsyncStorage.removeItem("MOVE");
  };

  return (
    <Modal
      isOpen={modal}
      className="modal-dialog-centered modal-md"
      toggle={(e) => cancel(e)}
    >
      <ModalHeader toggle={(e) => cancel(e)}>
        <span className="font-weight-bold">Perhatian</span>
      </ModalHeader>
      <ModalBody>
        <div className="my-1">
          <span className="font-weight-bold">Tinggalkan Halaman ?</span>
        </div>
        <div className="my-1">
          <span>Perubahan yang Anda buat mungkin tidak disimpan.</span>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          className="mr-1"
          color="success"
          onClick={(e) => cancel(e)}
          outline
        >
          Batal
        </Button>
        <Button
          color="primary"
          onClick={() => {
            if (action === "tab") {
              move(toggleMove, tab);
            } else if (action === "history") {
              history.push(path, data);
            } else if (action === "logout") {
              handleLogout();
            }
            setModal(!modal);
            removeStorage();
          }}
        >
          Tinggalkan
        </Button>
      </ModalFooter>
    </Modal>
  );
};
