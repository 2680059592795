import {
  deletebyid,
  get,
  getDataByParams,
  post,
  postDataByParams,
  postLogin,
  postWithManyFile,
  postWithOneImage,
  postWithTwoFile,
  postWithOneFile,
  postWithArrayData,
  postMetFormFile,
  postWithThreeFile,
  postWithThreeFileUpdate,
  postWithOneImageAppend,
  postDataNestedArray,
  postDataArray,
  postWithOneFileArray,
  download,
  postDataIcon,
  postDataArrayMany,
} from "../config/Method";
export const getData = async (data, panel) => {
  let key = "?";
  if (data) {
    for (let i in data) {
      key += "&" + i + "=" + data[i];
    }
  }

  return await get(`/panel/${panel}`, key)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const getDataApps = async (data, panel) => {
  let key = "?";
  if (data) {
    for (let i in data) {
      key += "&" + i + "=" + data[i];
    }
  }

  return await get(`/apps/${panel}`, key)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const getByParams = async (data, panel) => {
  return await getDataByParams(`/panel/${panel}`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const downloadFile = async (data, panel) => {
  return await download(`/panel/${panel}`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const postData = async (data, panel) => {
  return await post(`/panel/${panel}`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const postDataApps = async (data, panel) => {
  return await post(`/panel/${panel}`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const postDataWithParams = async (data, panel, params) => {
  return await postDataByParams(`/panel/${panel}`, data, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const postOneImage = async (data, image, panel) => {
  return await postWithOneImage(`/panel/${panel}`, data, image)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const postManyFile = async (data, image, files, deleted, panel) => {
  return await postWithManyFile(`/panel/${panel}`, data, image, files, deleted)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const postManyData = async (data, files, panel, deleted) => {
  return await postWithArrayData(`/panel/${panel}`, data, files, deleted)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const postOneFile = async (data, file, panel) => {
  return await postWithOneFile(`/panel/${panel}`, data, file)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const postOneFileArray = async (data, file, panel) => {
  return await postWithOneFileArray(`/panel/${panel}`, data, file)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const postTwoFile = async (data, file1, file2, deleted, panel) => {
  return await postWithTwoFile(`/panel/${panel}`, data, file1, file2, deleted)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const postThreeFile = async (
  data,
  file1,
  file2,
  file3,
  deleted,
  panel
) => {
  return await postWithThreeFile(
    `/panel/${panel}`,
    data,
    file1,
    file2,
    file3,
    deleted
  )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const updateThreeFile = async (
  data,
  data1,
  file1,
  file2,
  file3,
  deleted,
  panel
) => {
  return await postWithThreeFileUpdate(
    `/panel/${panel}`,
    data,
    data1,
    file1,
    file2,
    file3,
    deleted
  )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const deleteDatabyId = async (data, panel) => {
  return await deletebyid(`/panel/${panel}`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const changePassword = async (data) => {
  return await postLogin("/panel/login", data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const AddMultipleFile = async (data, panel, id, month, deleted) => {
  return await postMetFormFile(`/panel/${panel}`, data, id, month, deleted)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};
export const accessTarget = async (data, panel) => {
  return await post(`/panel/${panel}`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const postDataAndImageAppend = async (data, images, panel) => {
  return await postWithOneImageAppend(`/panel/${panel}`, data, images)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const postDataNested = async (data, images, panel, deleted) => {
  return await postDataNestedArray(`/panel/${panel}`, data, images, deleted)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const postDataWithArray = async (data, data1, panel, file) => {
  return await postDataArray(`/panel/${panel}`, data, data1, file)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const postWithIcon = async (data, data1, panel, icon, iconActive) => {
  return await postDataIcon(`/panel/${panel}`, data, data1, icon, iconActive)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const postDataManyArray = async (data, data1, data2, panel, file) => {
  return await postDataArrayMany(`/panel/${panel}`, data, data1, data2, file)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};
