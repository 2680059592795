export default function formatCurrency(nominal) {
  nominal = nominal ? nominal : 0;
  // nominal = nominal.replace(".", "");
  const price = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  }).format(nominal);
  return price.substring(0, price.length - 3);
}

export function formatCurrencyWithComma(nominal) {
  nominal = nominal ? nominal : 0;
  const price = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  }).format(nominal);
  return price;
}

export function checkAndformatCurrency(str) {
  if (str.match(/^\d+(\.\d*)?$/)) {
    return parseInt(Number(str) * 100, 10) // convert the number to cents
      .toString() // convert back to string
      .replace(/(..)$/, ".$1") // add dot, e.g. restore currency from cents
      .replace(/(.)(.{12})$/, "$1,$2") // add comma to billion
      .replace(/(.)(.{9})$/, "$1,$2") // add comma to million
      .replace(/(.)(.{6})$/, "$1,$2"); // add comma to thousand
  } else {
    return ""; // return empty string if format is incorrect
  }
}

export function formatCurrencyWithoutSymbol(nominal) {
  nominal = nominal ? nominal : 0;
  const formattedPrice = Math.floor(nominal).toLocaleString("id-ID");
  return formattedPrice;
}

export function formatCurrencyWithCount(nominal) {
  nominal = nominal ? nominal.toString() : 0;
  let format;
  if (nominal.length > 6) {
    const price = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      notation: "engineering",
    }).format(nominal);
    const priceSynmbol = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      notation: "compact",
    }).format(nominal);
    let simbol;
    if (priceSynmbol.includes("jt")) {
      simbol = priceSynmbol.slice(-2);
    } else {
      simbol = priceSynmbol.charAt(priceSynmbol.length - 1);
    }
    format = `${price.substring(0, price.length - 2)} ${simbol}`;
  } else {
    const price = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
    }).format(nominal);
    format = price.substring(0, price.length - 3);
  }
  return format;
}

export const formatCash = (n) => {
  let result;
  if (n === 0) result = n;
  if (n < 1e3) result = n;
  if (n >= 1e3 && n < 1e6) result = +(n / 1e3).toFixed(1) + " rb";
  if (n >= 1e6 && n < 1e9) result = +(n / 1e6).toFixed(2) + " jt";
  if (n >= 1e9 && n < 1e12) result = +(n / 1e9).toFixed(2) + " M";
  if (n >= 1e12) result = +(n / 1e12).toFixed(2) + " T";
  return `Rp ${result}`;
};
