import { postLogin } from "../config/Method";
import { AsyncStorage } from "reactjs-async-localstorage";

export const login = (data) => {
  return postLogin("/panel/login", data)
    .then(function (res) {
      AsyncStorage.setItem("Auth_ADM_TKN", res.access_token);
      AsyncStorage.setItem("USER_ROLE", res.user_role?.slug);
      AsyncStorage.setItem("USER_INFO", JSON.stringify(res.user_info));
      AsyncStorage.setItem("MENU", JSON.stringify(res.menu));

      return res;
    })
    .catch(function (err) {
      return err;
    });
};

export const logout = async () => {
  await AsyncStorage.clear();
  return true;
};

export const resetFilter = async () => {
  await AsyncStorage.removeItem("FILTER_PIPE");
  await AsyncStorage.removeItem("PIPE_DATE");
  await AsyncStorage.removeItem("SO_DATE");
  await AsyncStorage.removeItem("USER_FILTER_SO");
  await AsyncStorage.removeItem("COST_DATE");
  await AsyncStorage.removeItem("CUSTOMER_FILTER_COST");
  await AsyncStorage.removeItem("MARGIN_DATE");
  await AsyncStorage.removeItem("CUSTOMER_FILTER_MARGIN");
  await AsyncStorage.removeItem("BONUS_DATE");
  await AsyncStorage.removeItem("USER_FILTER_BONUS");
  await AsyncStorage.removeItem("CUSTOMER_FILTER_BONUS");
  await AsyncStorage.removeItem("PO_DATE");
  await AsyncStorage.removeItem("USER_FILTER_PO");
  await AsyncStorage.removeItem("DO_DATE");
  await AsyncStorage.removeItem("FILTER_DO");
  await AsyncStorage.removeItem("INVOICE_DATE");
  await AsyncStorage.removeItem("USER_FILTER_INVOICE");
  await AsyncStorage.removeItem("stateBrand");
  await AsyncStorage.removeItem("stateSolution");
  await AsyncStorage.removeItem("FILTER_CERTIFICATE");
  await AsyncStorage.removeItem("FilterCertificate");
};
