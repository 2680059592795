import React from "react";
import { useLocation } from "react-router-dom";

const FullPageLayout = ({ children, ...rest }) => {
  const location = useLocation();
  const pathname = location?.pathname;
  return (
    <div
    // className={classnames(
    //   "full-layout wrapper bg-full-screen-image blank-page dark-layout",
    //   {
    //     "layout-dark": themeConfig.layoutDark,
    //   }
    // )}
    >
      <div
        className={`container-fluid ${
          pathname !== "/" && pathname !== "/login" ? "p-0" : ""
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default FullPageLayout;
