import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
// import navigationConfig from "../../../../../configs/navigationConfig";
import SideMenuGroup from "./SideMenuGroup";
import { Badge } from "reactstrap";
import { ChevronRight } from "react-feather";
import { FormattedMessage } from "react-intl";

import { AsyncStorage } from "reactjs-async-localstorage";
import { resetFilter } from "../../../../../api/controller/AuthController";
import { getData } from "../../../../../api/controller/Controller";
import { ModalMovePage } from "../../../../../utility/movePage";
import { SERVICEIMAGE } from "../../../../../api/config/Config";
import { DefaultMenuOff, DefaultMenuOn } from "../../../../../assets/img/svg";

class SideMenuContent extends React.Component {
  constructor(props) {
    super(props);

    this.parentArr = [];
    this.collapsedPath = null;
    this.redirectUnauthorized = () => {
      // history.push("/misc/not-authorized");
    };
  }
  state = {
    flag: true,
    isHovered: false,
    activeGroups: [],
    currentActiveGroup: [],
    tempArr: [],
    countCertificate: 0,
    modalMove: false,
    modify: "",
    moveAction: "",
    path: "",
    menus: [],
  };

  handleRole = (role) => {
    this.setState({
      Roles: role ? role : "superadmin",
    });
  };

  handleGroupClick = (id, parent = null, type = "") => {
    let open_group = this.state.activeGroups;
    let active_group = this.state.currentActiveGroup;
    let temp_arr = this.state.tempArr;
    // Active Group to apply sidebar-group-active class
    if (type === "item" && parent === null) {
      active_group = [];
      temp_arr = [];
    } else if (type === "item" && parent !== null) {
      active_group = [];
      if (temp_arr.includes(parent)) {
        temp_arr.splice(temp_arr.indexOf(parent) + 1, temp_arr.length);
      } else {
        temp_arr = [];
        temp_arr.push(parent);
      }
      active_group = temp_arr.slice(0);
    } else if (type === "collapse" && parent === null) {
      temp_arr = [];
      temp_arr.push(id);
    } else if (type === "collapse" && parent !== null) {
      if (active_group.includes(parent)) {
        temp_arr = active_group.slice(0);
      }
      if (temp_arr.includes(id)) {
        // temp_arr.splice(temp_arr.indexOf(id), 1)
        temp_arr.splice(temp_arr.indexOf(id), temp_arr.length);
      } else {
        temp_arr.push(id);
      }
    } else {
      temp_arr = [];
    }

    if (type === "collapse") {
      // If open group does not include clicked group item
      if (!open_group.includes(id)) {
        // Get unmatched items that are not in the active group
        let temp = open_group.filter(function (obj) {
          return active_group.indexOf(obj) === -1;
        });
        // Remove those unmatched items from open group
        if (temp.length > 0 && !open_group.includes(parent)) {
          open_group = open_group.filter(function (obj) {
            return !temp.includes(obj);
          });
        }
        if (open_group.includes(parent) && active_group.includes(parent)) {
          open_group = active_group.slice(0);
        }
        // Add group item clicked in open group
        if (!open_group.includes(id)) {
          open_group.push(id);
        }
      } else {
        // If open group includes click group item, remove it from open group
        open_group.splice(open_group.indexOf(id), 1);
      }
    }
    if (type === "item") {
      open_group = active_group.slice(0);
    }

    this.setState({
      activeGroups: open_group,
      tempArr: temp_arr,
      currentActiveGroup: active_group,
    });
  };

  initRender = (parentArr) => {
    this.setState({
      activeGroups: parentArr.slice(0),
      currentActiveGroup: parentArr.slice(0),
      flag: false,
    });
  };

  fetchData = async () => {
    let data = await getData(null, "certificate/summary_dashboard");
    if (data?.status === 200) {
      this.setState({ ...this.state, countCertificate: data?.data });
    }
  };

  getModify = async () => {
    let modify = await AsyncStorage.getItem("MOVE");
    let data = JSON.parse(modify);
    this.setState({ ...this.state, modify: data });
  };

  getMenu = async () => {
    let getDataMenu = await AsyncStorage.getItem("MENU");
    if (getDataMenu && getDataMenu !== "null" && getDataMenu !== "undefined") {
      this.setState({ menus: JSON.parse(getDataMenu) });
    }
  };

  cancel = () => {
    this.setState({ ...this.state, modalMove: false });
  };

  componentDidMount() {
    AsyncStorage.getItem("USER_ROLE").then((res) => this.handleRole(res));
    this.initRender(this.parentArr[0] ? this.parentArr[0] : []);
    this.fetchData();
    this.getModify();
    this.getMenu();
  }

  componentDidUpdate(prevProps, prevState) {
    // AsyncStorage.getItem("USER_ROLE").then((res) => this.handleRole(res));
    if (prevProps.activePath !== this.props.activePath) {
      if (this.collapsedMenuPaths !== null) {
        this.props.collapsedMenuPaths(this.collapsedMenuPaths);
      }

      this.initRender(
        this.parentArr[0] ? this.parentArr[this.parentArr.length - 1] : []
      );
      this.fetchData();
      this.getModify();
      this.getMenu();
    }
  }

  checkRole(role) {
    if (
      role === "admin-finance" ||
      role === "lead-engineer" ||
      role === "lead-sales" ||
      role === "superadmin"
    ) {
      return true;
    } else {
      return false;
    }
  }

  checkAdmin(role) {
    if (
      role === "admin-finance" ||
      role === "admin-engineer" ||
      role === "admin-sales" ||
      role === "admin-purchase"
    ) {
      return true;
    } else {
      return false;
    }
  }

  menuActive(menu) {
    if (
      menu === "/approval-claims" ||
      menu === "/list-claims" ||
      menu === "/sales-order"
    ) {
      return true;
    } else {
      return false;
    }
  }

  checkTitle(title) {
    if (title.includes("(Sub Menu)")) {
      let result = title.replace(" (Sub Menu)", "");
      return result;
    } else if (title.includes("(Collapse)")) {
      let result = title.replace(" (Collapse)", "");
      return result;
    } else {
      return title;
    }
  }

  render() {
    const menuItems =
      this.state.menus.length > 0 &&
      this.state.menus.map((item) => {
        const CustomAnchorTag = item.type === "external-link" ? `a` : Link;
        // checks if item has groupheader
        if (item.type === "groupHeader") {
          // if (item.permissions && item.permissions.includes(this.state.Roles)) {
          return (
            <li className="navigation-header" key={`group-header-${item.id}`}>
              <span>{item.title}</span>
            </li>
          );
          // }
        }
        // let renderItem =
        //   this.state.Roles &&
        //   (this.checkRole(this.state.Roles) && item.id === "claims" ? (
        //     <li
        //       onMouseEnter={() => {
        //         this.getModify();
        //       }}
        //       onMouseLeave={() => {
        //         this.getModify();
        //       }}
        //       className={classnames("nav-item", {
        //         "has-sub": item.type === "collapse",
        //         open: this.state.activeGroups.includes(item.id),
        //         "sidebar-group-active": this.state.currentActiveGroup.includes(
        //           item.id
        //         ),
        //         hover: this.props.hoverIndex === item.id,
        //         active:
        //           (this.props.activeItemState === item.navLink &&
        //             item.type === "item") ||
        //           (item.parentOf &&
        //             item.parentOf.includes(this.props.activeItemState)),
        //         disabled: item.disabled,
        //       })}
        //       key={item.id}
        //       onClick={(e) => {
        //         e.stopPropagation();
        //         resetFilter();
        //         if (item.type === "item") {
        //           this.setState({
        //             ...this.state,
        //             modalMove: true,
        //             moveAction: "history",
        //             path: item.navLink,
        //           });
        //         } else {
        //           if (item.type === "item") {
        //             this.props.handleActiveItem(item.navLink);
        //             this.handleGroupClick(item.id, null, item.type);
        //             if (this.props.deviceWidth <= 1200 && item.type === "item") {
        //               this.props.toggleMenu();
        //             }
        //           } else {
        //             this.handleGroupClick(item.id, null, item.type);
        //           }
        //         }
        //       }}
        //     >
        //       <CustomAnchorTag
        //         to={
        //           this.state.modify
        //             ? "#"
        //             : item.filterBase
        //             ? item.filterBase
        //             : item.navLink && item.type === "item"
        //             ? item.navLink
        //             : ""
        //         }
        //         href={item.type === "external-link" ? item.navLink : ""}
        //         className={`d-flex ${
        //           item.badgeText
        //             ? "justify-content-between"
        //             : "justify-content-start"
        //         }`}
        //         onMouseEnter={() => {
        //           this.props.handleSidebarMouseEnter(item.id);
        //         }}
        //         onMouseLeave={() => {
        //           this.props.handleSidebarMouseEnter(item.id);
        //         }}
        //         key={item.id}
        //         onClick={(e) => {
        //           return item.type === "collapse" ? e.preventDefault() : "";
        //         }}
        //         target={item.newTab ? "_blank" : undefined}
        //       >
        //         <div className="menu-text">
        //           {this.props.activeItemState === item.navLink
        //             ? item.iconActive
        //             : item.icon}
        //           <span className="menu-item menu-title">
        //             <FormattedMessage id={item.title} />
        //           </span>
        //         </div>

        //         {item.badge ? (
        //           <div className="menu-badge">
        //             <Badge color={item.badge} className="mr-1" pill>
        //               {item.badgeText}
        //             </Badge>
        //           </div>
        //         ) : (
        //           ""
        //         )}
        //         {item.type === "collapse" ? (
        //           <ChevronRight className="menu-toggle-icon" size={13} />
        //         ) : (
        //           ""
        //         )}
        //       </CustomAnchorTag>
        //       {item.type === "collapse" ? (
        //         <SideMenuGroup
        //           group={item}
        //           handleGroupClick={this.handleGroupClick}
        //           activeGroup={this.state.activeGroups}
        //           handleActiveItem={this.props.handleActiveItem}
        //           activeItemState={this.props.activeItemState}
        //           handleSidebarMouseEnter={this.props.handleSidebarMouseEnter}
        //           activePath={this.props.activePath}
        //           hoverIndex={this.props.hoverIndex}
        //           initRender={this.initRender}
        //           parentArr={this.parentArr}
        //           triggerActive={undefined}
        //           currentActiveGroup={this.state.currentActiveGroup}
        //           permission={this.state.Roles}
        //           currentUser={this.state.Roles}
        //           redirectUnauthorized={this.redirectUnauthorized}
        //           collapsedMenuPaths={this.props.collapsedMenuPaths}
        //           toggleMenu={this.props.toggleMenu}
        //           deviceWidth={this.props.deviceWidth}
        //         />
        //       ) : (
        //         ""
        //       )}
        //     </li>
        //   ) : (this?.state?.Roles === "superadmin" &&
        //       (item.id === "SalesOrder" || item?.id === "leaves")) ||
        //     (this.checkAdmin(this?.state?.Roles) && item?.id === "leaves") ? (
        //     <li
        //       onMouseEnter={() => {
        //         this.getModify();
        //       }}
        //       onMouseLeave={() => {
        //         this.getModify();
        //       }}
        //       className={classnames("nav-item", {
        //         "has-sub": item.type === "collapse",
        //         open: this.state.activeGroups.includes(item.id),
        //         "sidebar-group-active": this.state.currentActiveGroup.includes(
        //           item.id
        //         ),
        //         hover: this.props.hoverIndex === item.id,
        //         active:
        //           (this.props.activeItemState === item.navLink &&
        //             item.type === "item") ||
        //           (item.parentOf &&
        //             item.parentOf.includes(this.props.activeItemState)),
        //         disabled: item.disabled,
        //       })}
        //       key={item.id}
        //       onClick={(e) => {
        //         e.stopPropagation();
        //         resetFilter();
        //         if (this.state.modify) {
        //           if (item.type === "item") {
        //             this.setState({
        //               ...this.state,
        //               modalMove: true,
        //               moveAction: "history",
        //               path: item.navLink,
        //             });
        //           } else {
        //             this.handleGroupClick(item.id, null, item.type);
        //           }
        //         } else {
        //           if (item.type === "item") {
        //             this.props.handleActiveItem(item.navLink);
        //             this.handleGroupClick(item.id, null, item.type);
        //             if (this.props.deviceWidth <= 1200 && item.type === "item") {
        //               this.props.toggleMenu();
        //             }
        //           } else {
        //             this.handleGroupClick(item.id, null, item.type);
        //           }
        //         }
        //       }}
        //       style={
        //         item.id === "claims" ||
        //         item.id === "approval-claims" ||
        //         item.id === "list-claims"
        //           ? { marginBottom: "5rem" }
        //           : null
        //       }
        //     >
        //       <CustomAnchorTag
        //         to={
        //           this.state.modify
        //             ? "#"
        //             : item.filterBase
        //             ? item.filterBase
        //             : item.navLink && item.type === "item"
        //             ? item.navLink
        //             : ""
        //         }
        //         href={item.type === "external-link" ? item.navLink : ""}
        //         className={`d-flex ${
        //           item.badgeText
        //             ? "justify-content-between"
        //             : "justify-content-start"
        //         }`}
        //         onMouseEnter={() => {
        //           this.props.handleSidebarMouseEnter(item.id);
        //         }}
        //         onMouseLeave={() => {
        //           this.props.handleSidebarMouseEnter(item.id);
        //         }}
        //         key={item.id}
        //         onClick={(e) => {
        //           return item.type === "collapse" ? e.preventDefault() : "";
        //         }}
        //         target={item.newTab ? "_blank" : undefined}
        //       >
        //         <div className="menu-text">
        //           {this.props.activeItemState === item.navLink
        //             ? item.iconActive
        //             : item.icon}
        //           <span className="menu-item menu-title">
        //             <FormattedMessage id={item.title} />
        //           </span>
        //         </div>

        //         {item.badge ? (
        //           <div className="menu-badge">
        //             <Badge color={item.badge} className="mr-1" pill>
        //               {item.badgeText}
        //             </Badge>
        //           </div>
        //         ) : (
        //           ""
        //         )}
        //         {item.type === "collapse" ? (
        //           <ChevronRight className="menu-toggle-icon" size={13} />
        //         ) : (
        //           ""
        //         )}
        //       </CustomAnchorTag>
        //       {item.type === "collapse" ? (
        //         <SideMenuGroup
        //           group={item}
        //           handleGroupClick={this.handleGroupClick}
        //           activeGroup={this.state.activeGroups}
        //           handleActiveItem={this.props.handleActiveItem}
        //           activeItemState={this.props.activeItemState}
        //           handleSidebarMouseEnter={this.props.handleSidebarMouseEnter}
        //           activePath={this.props.activePath}
        //           hoverIndex={this.props.hoverIndex}
        //           initRender={this.initRender}
        //           parentArr={this.parentArr}
        //           triggerActive={undefined}
        //           currentActiveGroup={this.state.currentActiveGroup}
        //           permission={this.state.Roles}
        //           currentUser={this.state.Roles}
        //           redirectUnauthorized={this.redirectUnauthorized}
        //           collapsedMenuPaths={this.props.collapsedMenuPaths}
        //           toggleMenu={this.props.toggleMenu}
        //           deviceWidth={this.props.deviceWidth}
        //         />
        //       ) : (
        //         ""
        //       )}
        //     </li>
        //   ) : item.type === "item" ? (
        //     <li
        //       onMouseEnter={() => {
        //         this.getModify();
        //       }}
        //       onMouseLeave={() => {
        //         this.getModify();
        //       }}
        //       className={classnames("nav-item", {
        //         "sidebar-group-active": this.state.currentActiveGroup.includes(
        //           item.id
        //         ),
        //         hover: this.props.hoverIndex === item.id,
        //         active:
        //           (this.props.activeItemState === item.navLink &&
        //             item.type === "item") ||
        //           (item.parentOf &&
        //             item.parentOf.includes(this.props.activeItemState)),
        //         disabled: item.disabled,
        //       })}
        //       key={item.id}
        //       onClick={(e) => {
        //         e.stopPropagation();
        //         resetFilter();
        //         if (this.state.modify) {
        //           if (item.type === "item") {
        //             this.setState({
        //               ...this.state,
        //               modalMove: true,
        //               moveAction: "history",
        //               path: item.navLink,
        //             });
        //           }
        //         } else {
        //           if (item.type === "item") {
        //             this.props.handleActiveItem(item.navLink);
        //             this.handleGroupClick(item.id, null, item.type);
        //             if (this.props.deviceWidth <= 1200 && item.type === "item") {
        //               this.props.toggleMenu();
        //             }
        //           } else {
        //             this.handleGroupClick(item.id, null, item.type);
        //           }
        //         }
        //       }}
        //     >
        //       <CustomAnchorTag
        //         to={
        //           this.state.modify
        //             ? "#"
        //             : item.filterBase
        //             ? item.filterBase
        //             : item.navLink && item.type === "item"
        //             ? item.navLink
        //             : ""
        //         }
        //         href={item.type === "external-link" ? item.navLink : ""}
        //         className={`d-flex ${
        //           item.badgeText || item?.id === "certificateSuperAdmin"
        //             ? "justify-content-between"
        //             : "justify-content-start"
        //         }`}
        //         onMouseEnter={() => {
        //           this.props.handleSidebarMouseEnter(item.id);
        //         }}
        //         onMouseLeave={() => {
        //           this.props.handleSidebarMouseEnter(item.id);
        //         }}
        //         key={item.id}
        //         onClick={(e) => {
        //           return item.type === "collapse" ? e.preventDefault() : "";
        //         }}
        //         target={item.newTab ? "_blank" : undefined}
        //       >
        //         <div className="menu-text">
        //           {this.props.activeItemState === item.navLink
        //             ? item.iconActive
        //             : item.icon}
        //           <span className="menu-item menu-title">
        //             {item?.id === "certificateSuperAdmin" &&
        //             this.state.countCertificate > 0 ? (
        //               <span className="menu-badge">
        //                 <FormattedMessage id={item.title} />
        //                 <Badge
        //                   color={"primary"}
        //                   className={`ml-1 ${
        //                     this?.props?.activeItemState === item?.navLink
        //                       ? "bg-white text-dark"
        //                       : ""
        //                   }`}
        //                   pill
        //                 >
        //                   {this.state.countCertificate}
        //                 </Badge>
        //               </span>
        //             ) : (
        //               <FormattedMessage id={item.title} />
        //             )}
        //           </span>
        //         </div>

        //         {item.badge ? (
        //           <div className="menu-badge">
        //             <Badge color={item.badge} pill>
        //               {item?.badgeText}
        //             </Badge>
        //           </div>
        //         ) : null}
        //       </CustomAnchorTag>
        //     </li>
        //   ) : (
        //     item.type === "collapse" &&
        //     item.children &&
        //     item.children.map((child) =>
        //       (child.permissions &&
        //         child.permissions.includes(this.state.Roles)) ||
        //       child.permissions === undefined ? (
        //         <li
        //           onMouseEnter={() => {
        //             this.getModify();
        //           }}
        //           onMouseLeave={() => {
        //             this.getModify();
        //           }}
        //           className={classnames("nav-item", {
        //             "sidebar-group-active":
        //               this.state.currentActiveGroup.includes(child.id),
        //             hover: this.props.hoverIndex === child.id,
        //             active:
        //               (this.props.activeItemState === child.navLink &&
        //                 child.type === "item") ||
        //               (child.parentOf &&
        //                 child.parentOf.includes(this.props.activeItemState)),
        //             disabled: child.disabled,
        //           })}
        //           key={child.id}
        //           onClick={(e) => {
        //             e.stopPropagation();
        //             resetFilter();
        //             if (this.state.modify) {
        //               if (child.type === "item") {
        //                 this.setState({
        //                   ...this.state,
        //                   modalMove: true,
        //                   moveAction: "history",
        //                   path: child.navLink,
        //                 });
        //               }
        //             } else {
        //               if (child.type === "item") {
        //                 this.props.handleActiveItem(child.navLink);
        //                 this.handleGroupClick(child.id, null, child.type);
        //                 if (
        //                   this.props.deviceWidth <= 1200 &&
        //                   child.type === "item"
        //                 ) {
        //                   this.props.toggleMenu();
        //                 }
        //               } else {
        //                 this.handleGroupClick(child.id, null, child.type);
        //               }
        //             }
        //           }}
        //           style={
        //             item.id === "claims" ||
        //             item.id === "approval-claims" ||
        //             item.id === "list-claims"
        //               ? { marginBottom: "5rem" }
        //               : null
        //           }
        //         >
        //           <CustomAnchorTag
        //             to={
        //               this.state.modify
        //                 ? "#"
        //                 : child.filterBase
        //                 ? child.filterBase
        //                 : child.navLink && child.type === "item"
        //                 ? child.navLink
        //                 : ""
        //             }
        //             href={child.type === "external-link" ? child.navLink : ""}
        //             className={`d-flex ${
        //               child.badgeText
        //                 ? "justify-content-between"
        //                 : "justify-content-start"
        //             }`}
        //             onMouseEnter={() => {
        //               this.props.handleSidebarMouseEnter(child.id);
        //             }}
        //             onMouseLeave={() => {
        //               this.props.handleSidebarMouseEnter(child.id);
        //             }}
        //             key={child.id}
        //             target={child.newTab ? "_blank" : undefined}
        //           >
        //             <div className="menu-text">
        //               {this.props.activeItemState === child.navLink
        //                 ? item.iconActive
        //                 : item.icon}
        //               <span className="menu-item menu-title">
        //                 <FormattedMessage id={child.title} />
        //               </span>
        //             </div>

        //             {child.badge ? (
        //               <div className="menu-badge">
        //                 <Badge color={child.badge} className="mr-1" pill>
        //                   {child.badgeText}
        //                 </Badge>
        //               </div>
        //             ) : (
        //               ""
        //             )}
        //           </CustomAnchorTag>
        //         </li>
        //       ) : (
        //         ""
        //       )
        //     )
        //   ));

        let renderItem = (
          <li
            onMouseEnter={() => {
              this.getModify();
            }}
            onMouseLeave={() => {
              this.getModify();
            }}
            className={classnames("nav-item", {
              "has-sub": item.type === "collapse",
              open: this.state.activeGroups.includes(item.id),
              "sidebar-group-active": this.state.currentActiveGroup.includes(
                item.id
              ),
              hover: this.props.hoverIndex === item.id,
              active:
                (this.props.activeItemState === item.navLink &&
                  item.type === "item") ||
                (item.parentOf &&
                  item.parentOf.includes(this.props.activeItemState)),
              disabled: item.disabled,
            })}
            key={item.id}
            onClick={(e) => {
              e.stopPropagation();
              resetFilter();
              if (item.type === "item") {
                if (this.state.modify) {
                  this.setState({
                    ...this.state,
                    modalMove: true,
                    moveAction: "history",
                    path: item.navLink,
                  });
                } else {
                  this.props.handleActiveItem(item.navLink);
                  this.handleGroupClick(item.id, null, item.type);
                  if (this.props.deviceWidth <= 1200 && item.type === "item") {
                    this.props.toggleMenu();
                  }
                }
              } else {
                this.handleGroupClick(item.id, null, item.type);
              }
            }}
          >
            <CustomAnchorTag
              to={
                this.state.modify
                  ? "#"
                  : item.filterBase
                  ? item.filterBase
                  : item.navLink && item.type === "item"
                  ? item.navLink
                  : ""
              }
              href={item.type === "external-link" ? item.navLink : ""}
              className={`d-flex ${
                item.badgeText
                  ? "justify-content-between"
                  : "justify-content-start"
              }`}
              onMouseEnter={() => {
                this.props.handleSidebarMouseEnter(item.id);
              }}
              onMouseLeave={() => {
                this.props.handleSidebarMouseEnter(item.id);
              }}
              key={item.id}
              onClick={(e) => {
                return item.type === "collapse" ? e.preventDefault() : "";
              }}
              target={item.newTab ? "_blank" : undefined}
            >
              <div className="menu-text">
                {this.props.activeItemState === item.navLink ? (
                  item.iconActive ? (
                    <img
                      src={SERVICEIMAGE + "/storage/" + item.iconActive}
                      alt="iconActive"
                      height={18}
                      width={18}
                      onError={(e) => {
                        e.currentTarget.src = DefaultMenuOn;
                      }}
                    />
                  ) : (
                    <img
                      src={DefaultMenuOn}
                      alt="iconActive"
                      height={18}
                      width={18}
                    />
                  )
                ) : item.icon ? (
                  <img
                    src={SERVICEIMAGE + "/storage/" + item.icon}
                    alt="avatarImg"
                    height={18}
                    width={18}
                    onError={(e) => {
                      e.currentTarget.src = DefaultMenuOff;
                    }}
                  />
                ) : (
                  <img
                    src={DefaultMenuOff}
                    alt="avatarImg"
                    height={18}
                    width={18}
                  />
                )}
                <span className="menu-item menu-title ml-1">
                  {item.title === "Certificate" &&
                  this.state.countCertificate > 0 ? (
                    <span className="menu-badge">
                      <FormattedMessage id={this.checkTitle(item.title)} />
                      <Badge
                        color="primary"
                        className={`ml-1 ${
                          this.props.activeItemState === item.navLink
                            ? "bg-white text-dark"
                            : ""
                        }`}
                        pill
                      >
                        {this.state.countCertificate}
                      </Badge>
                    </span>
                  ) : (
                    <FormattedMessage id={this.checkTitle(item.title)} />
                  )}
                </span>
              </div>

              {item.badge ? (
                <div className="menu-badge">
                  <Badge color={item.badge} className="mr-1" pill>
                    {item.badgeText}
                  </Badge>
                </div>
              ) : (
                ""
              )}
              {item.type === "collapse" ? (
                <ChevronRight className="menu-toggle-icon" size={13} />
              ) : (
                ""
              )}
            </CustomAnchorTag>
            {item.type === "collapse" ? (
              <SideMenuGroup
                group={item}
                handleGroupClick={this.handleGroupClick}
                activeGroup={this.state.activeGroups}
                handleActiveItem={this.props.handleActiveItem}
                activeItemState={this.props.activeItemState}
                handleSidebarMouseEnter={this.props.handleSidebarMouseEnter}
                activePath={this.props.activePath}
                hoverIndex={this.props.hoverIndex}
                initRender={this.initRender}
                parentArr={this.parentArr}
                triggerActive={undefined}
                currentActiveGroup={this.state.currentActiveGroup}
                permission={this.props.permission}
                currentUser={this.props.currentUser}
                redirectUnauthorized={this.redirectUnauthorized}
                collapsedMenuPaths={this.props.collapsedMenuPaths}
                toggleMenu={this.props.toggleMenu}
                deviceWidth={this.props.deviceWidth}
              />
            ) : (
              ""
            )}
          </li>
        );

        if (
          item.navLink &&
          item.collapsed !== undefined &&
          item.collapsed === true
        ) {
          this.collapsedPath = item.navLink;
          this.props.collapsedMenuPaths(item.navLink);
        }

        if (
          item.type === "collapse" ||
          item.type === "external-link" ||
          (item.type === "item" &&
            item.permissions &&
            item.permissions.includes(
              this.state.Roles ? this.state.Roles : "superadmin"
            )) ||
          item.permissions === undefined
        ) {
          return renderItem;
        } else if (
          item.type === "item" &&
          item.navLink === this.props.activePath &&
          !item.permissions.includes(
            this.state.Roles ? this.state.Roles : "superadmin"
          )
        ) {
          return this.redirectUnauthorized();
        }
        return true;
      });
    return (
      <React.Fragment>
        <ModalMovePage
          modal={this.state.modalMove}
          setModal={(e) => this.setState({ modalMove: e })}
          action={this.state.moveAction}
          cancel={this.cancel}
          path={this.state.path}
          // move={move}
          // toggleMove={toggle}
        />
        {menuItems}
      </React.Fragment>
    );
  }
}
export default SideMenuContent;
