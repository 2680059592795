import { AsyncStorage } from "reactjs-async-localstorage";
import { BASE_URL } from "./Config";
import Axios from "axios";

export const postLogin = async (url, data) => {
  return await Axios.post(BASE_URL + url, data, {
    headers: { Accept: "application/json" },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

// get data
export const get = async (url, data) => {
  let token = await AsyncStorage.getItem("Auth_ADM_TKN");
  return await Axios.get(BASE_URL + url + data, {
    headers: { Authorization: "Bearer " + token, Accept: "application/json" },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const download = async (url, data) => {
  let token = await AsyncStorage.getItem("Auth_ADM_TKN");
  let formData = new FormData();

  if (data) {
    for (let x in data) {
      formData.set(x, data[x]);
    }
  }

  return await Axios.post(BASE_URL + url, formData, {
    headers: { Authorization: "Bearer " + token, Accept: "application/json" },
    responseType: "blob",
    // params: data,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

// post data
export const post = async (url, data) => {
  let token = await AsyncStorage.getItem("Auth_ADM_TKN");

  return await Axios.post(BASE_URL + url, data, {
    headers: {
      Authorization: "Bearer " + token,
      Accept: "application/json",
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

// delete data by id
export const deletebyid = async (url, id, err) => {
  let token = await AsyncStorage.getItem("Auth_ADM_TKN");
  return await Axios.delete(BASE_URL + url + `?id=${id}`, {
    headers: {
      Authorization: "Bearer " + token,
      Accept: "application/json",
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

// post data dengan 1 image
export const postWithOneImage = async (url, data, image) => {
  let token = await AsyncStorage.getItem("Auth_ADM_TKN");
  let formData = new FormData();

  if (data) {
    for (let x in data) {
      formData.set(x, data[x]);
    }
  }

  if (image) {
    formData.append("image", image, image.name);
  }
  return await Axios.post(BASE_URL + url, formData, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const postWithManyFile = async (url, data, image, files, deleted) => {
  let token = await AsyncStorage.getItem("Auth_ADM_TKN");
  let formData = new FormData();

  if (deleted) {
    formData.set("deleted", JSON.stringify(deleted));
  }
  if (data) {
    for (let x in data) {
      formData.set(x, data[x]);
    }
  }
  if (files) {
    for (let i in files) {
      formData.append("file[]", files[i], files[i].name);
    }
  }

  if (image) {
    formData.append("image", image, image.name);
  }
  return await Axios.post(BASE_URL + url, formData, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const postWithArrayData = async (url, data, files, deleted) => {
  let token = await AsyncStorage.getItem("Auth_ADM_TKN");
  let formData = new FormData();

  if (deleted) {
    formData.set("deleted", JSON.stringify(deleted));
  }
  if (data) {
    for (let x in data) {
      formData.set(x, data[x]);
    }
  }
  if (files) {
    for (let i in files) {
      formData.append("data[]", files[i]);
    }
  }
  return await Axios.post(BASE_URL + url, formData, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const postWithOneFile = async (url, data, file) => {
  let token = await AsyncStorage.getItem("Auth_ADM_TKN");
  let formData = new FormData();

  if (data) {
    for (let x in data) {
      formData.set(x, data[x]);
    }
  }
  if (file) {
    if (typeof file !== "string") {
      formData.append("file", file, file.name);
    } else {
      formData.append("file", file);
    }
  }
  return await Axios.post(BASE_URL + url, formData, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const postWithOneFileArray = async (url, data, file) => {
  let token = await AsyncStorage.getItem("Auth_ADM_TKN");
  let formData = new FormData();

  if (data) {
    for (let x in data) {
      formData.set(x, data[x]);
    }
  }
  if (file) {
    for (let i in file) {
      formData.append("file[]", file[i], file[i].name);
    }
  }
  return await Axios.post(BASE_URL + url, formData, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const postWithTwoFile = async (url, data, file1, file2, deleted) => {
  let token = await AsyncStorage.getItem("Auth_ADM_TKN");
  let formData = new FormData();

  if (deleted) {
    formData.set("deleted", JSON.stringify(deleted));
  }
  if (data) {
    for (let x in data) {
      formData.set(x, data[x]);
    }
  }
  if (file1) {
    for (let i in file1) {
      formData.append("file1[]", file1[i], file1[i].name);
    }
  }

  if (file2) {
    for (let i in file2) {
      formData.append("file2[]", file2[i], file2[i].name);
    }
  }

  return await Axios.post(BASE_URL + url, formData, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const postWithThreeFile = async (
  url,
  data,
  file1,
  file2,
  file3,
  deleted
) => {
  let token = await AsyncStorage.getItem("Auth_ADM_TKN");
  let formData = new FormData();

  if (deleted) {
    formData.set("deleted", JSON.stringify(deleted));
  }
  if (data) {
    for (let x in data) {
      formData.set(x, data[x]);
    }
  }
  if (file1) {
    for (let i in file1) {
      formData.append("file1[]", file1[i], file1[i].name);
    }
  }

  if (file2) {
    for (let i in file2) {
      formData.append("file2[]", file2[i], file2[i].name);
    }
  }

  if (file3) {
    for (let i in file3) {
      formData.append("file3[]", file3[i], file3[i].name);
    }
  }

  return await Axios.post(BASE_URL + url, formData, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const postWithThreeFileUpdate = async (
  url,
  data,
  data1,
  file1,
  file2,
  file3,
  deleted
) => {
  let token = await AsyncStorage.getItem("Auth_ADM_TKN");
  let formData = new FormData();

  if (deleted && deleted.length > 0) {
    formData.set("deleted", JSON.stringify(deleted));
  }
  if (data) {
    for (let x in data) {
      formData.set(x, data[x]);
    }
  }
  if (data1) {
    formData.set("data1", JSON.stringify(data1));
  }
  if (file1) {
    formData.append("dataFile1", JSON.stringify(file1));
    for (let i in file1) {
      formData.append(`file1index${i}`, file1[i].file);
    }
  }

  if (file2) {
    formData.append("dataFile2", JSON.stringify(file2));
    for (let i in file2) {
      formData.append(`file2index${i}`, file2[i].file);
    }
  }

  if (file3) {
    formData.append("dataFile3", JSON.stringify(file3));
    for (let i in file3) {
      formData.append(`file3index${i}`, file3[i].file);
    }
  }

  return await Axios.post(BASE_URL + url, formData, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const getDataByParams = async (url, data) => {
  let token = await AsyncStorage.getItem("Auth_ADM_TKN");
  return await Axios.get(BASE_URL + url, {
    headers: { Authorization: "Bearer " + token, Accept: "application/json" },
    params: data,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const postDataByParams = async (url, data, params) => {
  let token = await AsyncStorage.getItem("Auth_ADM_TKN");
  return await Axios.post(BASE_URL + url, data, {
    headers: { Authorization: "Bearer " + token, Accept: "application/json" },
    params: params,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const postMetFormFile = async (url, data, id, month, deleted) => {
  let token = await AsyncStorage.getItem("Auth_ADM_TKN");
  let formData = new FormData();

  if (id) {
    formData.append("id", JSON.stringify(id));
  }
  if (month) {
    formData.append("month", JSON.stringify(month));
  }
  if (data) {
    formData.append("data", JSON.stringify(data));
    for (let i in data) {
      if (data[i].file && data[i].file.name) {
        formData.append(`index_${i}`, data[i].file, data[i].file.name);
      }
    }
  }
  if (deleted) {
    formData.append("deleted", JSON.stringify(deleted));
  }
  return await Axios.post(BASE_URL + url, formData, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const postWithOneImageAppend = async (url, data, images) => {
  let token = await AsyncStorage.getItem("Auth_ADM_TKN");
  let formData = new FormData();

  if (data) {
    for (let x in data) {
      formData.set(x, data[x]);
      if (x === "date") {
        for (let y of data[x]) {
          formData.append("date[]", y);
        }
      }
    }
  }

  if (images) {
    for (let i of images) {
      formData.append("file[]", i);
    }
  }
  return await Axios.post(BASE_URL + url, formData, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const postDataNestedArray = async (url, data, image, deleted) => {
  // post data, array, & image
  let token = await AsyncStorage.getItem("Auth_ADM_TKN");
  let formData = new FormData();

  if (data) {
    for (let x in data) {
      formData.set(x, data[x]);
    }
  }

  if (data.pic) {
    for (let i in data.pic) {
      for (let n in data.pic[i]) {
        formData.append(`pic[${i}][${n}]`, data.pic[i][n]);
      }
    }
  }

  if (image) {
    formData.append("image", image, image?.name);
  }

  if (deleted && deleted.length > 0) {
    formData.set("deleted", JSON.stringify(deleted));
  }
  return await Axios.post(BASE_URL + url, formData, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
export const postDataArray = async (url, data, data1, file) => {
  let token = await AsyncStorage.getItem("Auth_ADM_TKN");
  let formData = new FormData();

  if (data) {
    for (let x in data) {
      formData.set(x, data[x]);
    }
  }

  if (data1) {
    formData.set("data1", JSON.stringify(data1));
  }

  if (file) {
    if (typeof file !== "string") {
      formData.append("file", file, file.name);
    } else {
      formData.append("file", file);
    }
  }
  return await Axios.post(BASE_URL + url, formData, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const postDataIcon = async (url, data, data1, icon, iconActive) => {
  let token = await AsyncStorage.getItem("Auth_ADM_TKN");
  let formData = new FormData();

  if (data) {
    for (let x in data) {
      formData.set(x, data[x]);
    }
  }

  if (data1) {
    formData.set("data1", JSON.stringify(data1));
  }

  if (icon) {
    formData.append("icon", icon, icon.name);
  }

  if (iconActive) {
    formData.append("iconActive", iconActive, iconActive.name);
  }

  return await Axios.post(BASE_URL + url, formData, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
export const postDataArrayMany = async (url, data, data1, data2, file) => {
  let token = await AsyncStorage.getItem("Auth_ADM_TKN");
  let formData = new FormData();

  if (data) {
    for (let x in data) {
      formData.set(x, data[x]);
    }
  }

  if (data1) {
    formData.set("data1", JSON.stringify(data1));
  }

  if (data2) {
    formData.set("data2", JSON.stringify(data2));
  }

  if (file) {
    if (typeof file !== "string") {
      formData.append("file", file, file.name);
    } else {
      formData.append("file", file);
    }
  }
  return await Axios.post(BASE_URL + url, formData, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
