import React, { Component } from "react";

import classnames from "classnames";
import Logo from "../../../../assets/img/logo/LogoNoBackground.png";
import Axios from "axios";
import { BASE_URL, SERVICEIMAGE } from "../../../../api/config/Config";
import { AsyncStorage } from "reactjs-async-localstorage";
import { ThreeDots } from "react-loading-icons";
class SidebarHeader extends Component {
  state = {
    data: null,
    loading: true,
  };
  async componentDidMount() {
    this.setState({ loading: true });
    let token = await AsyncStorage.getItem("Auth_ADM_TKN");
    Axios.get(`${BASE_URL}/panel/assets/detail?type=logo&layout=landscape`, {
      headers: { Authorization: "Bearer " + token, Accept: "application/json" },
    })
      .then((response) => {
        this.setState({ data: response.data.data });
        setTimeout(() => {
          this.setState({ loading: false });
        }, 2000);
      })
      .catch((error) => this.setState({ loading: false }));
  }
  render() {
    let { toggleSidebarMenu, collapsed, toggle, menuShadow, sidebarState } =
      this.props;
    return (
      <div className="navbar-header" style={{ height: "auto" }}>
        <ul
          className="nav navbar-nav cursor-pointer"
          onClick={() => {
            toggleSidebarMenu(collapsed ? false : true);
            toggle();
          }}
        >
          <li
            className={`nav-item ${
              collapsed && sidebarState ? "mr-auto" : "mx-auto"
            }`}
          >
            <div
              className="navbar-brand justify-content-center align-items-center"
              style={{ minHeight: 70 }}
            >
              {this.state.loading && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    backgroundColor: "#fff",
                    paddingRight: 20,
                    paddingLeft: 20,
                    height: 100,
                    width: 200,

                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="d-flex"
                >
                  <ThreeDots fill="#005391" height={50} width={50} />
                </div>
              )}

              {this.state?.data && this.state?.data.file ? (
                <img
                  className="brand"
                  src={`${SERVICEIMAGE}/storage/${this.state?.data?.file}`}
                  alt=""
                  style={{
                    width: collapsed && sidebarState ? "50px" : "115px",
                    marginLeft: collapsed && sidebarState ? -8 : 0,
                    marginBottom: 20,
                    height: "auto",
                  }}
                />
              ) : (
                !this.state.loading && (
                  <img
                    className="brand"
                    src={Logo}
                    alt=""
                    style={{
                      width: collapsed && sidebarState ? "50px" : "115px",
                      marginLeft: collapsed && sidebarState ? -8 : 0,
                      marginBottom: 20,
                      height: "auto",
                    }}
                  />
                )
              )}
            </div>
          </li>

          {/* <li className="nav-item nav-toggle">
            <div className="nav-link modern-nav-toggle">
              {collapsed === false ? (
                <Disc
                  onClick={() => {
                    toggleSidebarMenu(true);
                    toggle();
                  }}
                  className={classnames(
                    "toggle-icon icon-x d-none d-xl-block font-medium-4",
                    {
                      "text-primary": activeTheme === "primary",
                      "text-success": activeTheme === "success",
                      "text-danger": activeTheme === "danger",
                      "text-info": activeTheme === "info",
                      "text-warning": activeTheme === "warning",
                      "text-dark": activeTheme === "dark",
                    }
                  )}
                  size={20}
                  data-tour="toggle-icon"
                />
              ) : (
                <Circle
                  onClick={() => {
                    toggleSidebarMenu(false);
                    toggle();
                  }}
                  className={classnames(
                    "toggle-icon icon-x d-none d-xl-block font-medium-4",
                    {
                      "text-primary": activeTheme === "primary",
                      "text-success": activeTheme === "success",
                      "text-danger": activeTheme === "danger",
                      "text-info": activeTheme === "info",
                      "text-warning": activeTheme === "warning",
                      "text-dark": activeTheme === "dark",
                    }
                  )}
                  size={20}
                />
              )}
              <X
                onClick={sidebarVisibility}
                className={classnames(
                  "toggle-icon icon-x d-block d-xl-none font-medium-4",
                  {
                    "text-primary": activeTheme === "primary",
                    "text-success": activeTheme === "success",
                    "text-danger": activeTheme === "danger",
                    "text-info": activeTheme === "info",
                    "text-warning": activeTheme === "warning",
                    "text-dark": activeTheme === "dark",
                  }
                )}
                size={20}
              />
            </div>
          </li> */}
        </ul>
        <div
          className={classnames("shadow-bottom", {
            "d-none": menuShadow === false,
          })}
        />
      </div>
    );
  }
}
export default SidebarHeader;
