export const WarningBlack = require("./files/warning-black.svg");
export const DonutChartOrange = require("./files/donut-chart-orange.svg");
export const LineChartDownRed = require("./files/line-chart-down-red.svg");
export const ChatIcon = require("./files/chat-icon.svg");
export const UpGraph = require("./files/up-graph.svg");
export const BarChartGreen = require("./files/bar-chart.svg");
export const TrumpetGrey = require("./files/trumpet-grey.svg");
export const CalendarBlue = require("./files/calendar-blue.svg");
export const PaperPlusGrey = require("./files/paper-plus.svg");
// export const ArrowRightBlue = require("./files/arrow-right-blue.svg");
export const EyeBlack = require("./files/eye-black.svg");
export const FileBlack = require("./files/file-black.svg");
export const UnderConstruction = require("./files/under-construction.svg");
export const CardBlue = require("./files/card-blue.svg");
export const DocumentGray = require("./files/document-gray.svg");
export const DocumentPlus = require("./files/document-plus.svg");
export const BagGray = require("./files/bag-gray.svg");
export const BagGrayActive = require("./files/bag-gray-active.svg");
export const DollarGreen = require("./dollar.svg");
export const DollarPurple = require("./dollar-purple.svg");
export const CheckGreen = require("./check.svg");

// Menu Sidebar
// M = Menu, W = White
export const MDashboard = require("./newIcon/Dahboard-black.svg");
export const MDashboardWhite = require("./newIcon/Dahboard.svg");
export const MGroup = require("./newIcon/PETUGAS.svg");
export const MGroupW = require("./newIcon/PETUGASW.svg");
export const MRole = require("./newIcon/fi-rr-portrait.svg");
export const MRoleW = require("./newIcon/fi-rr-portraitW.svg");
export const MUser = require("./newIcon/PETUGAS-one.svg");
export const MUserW = require("./newIcon/PETUGAS-oneW.svg");
export const MPipeline = require("./newIcon/Frame-1837.svg");
export const MPipelineW = require("./newIcon/Frame-1837W.svg");
export const MSalesTarget = require("./newIcon/fi-rr-chat-arrow-grow.svg");
export const MSalesTargetW = require("./newIcon/fi-rr-chat-arrow-growW.svg");
export const MNavbarSO = require("./newIcon/fi-rr-sales-order.svg");
export const MNavbarSOW = require("./newIcon/fi-rr-sales-orderW.svg");
export const MNavbarPO = require("./newIcon/fi-rr-purchase-order.svg");
export const MNavbarPOW = require("./newIcon/fi-rr-purchase-orderW.svg");
export const MNavbarDO = require("./newIcon/fi-rr-truck-side.svg");
export const MNavbarDOW = require("./newIcon/fi-rr-truck-sideW.svg");
export const IconFilter = require("./newIcon/fi-rr-filter.svg");
export const MNavbarInvoice = require("./newIcon/fi-rr-receipt.svg");
export const MNavbarInvoiceW = require("./newIcon/fi-rr-receiptW.svg");
export const MService = require("./newIcon/Frame-1838.svg");
export const MServiceW = require("./newIcon/Frame-1838W.svg");
export const MCalendar = require("./newIcon/fi-rr-calendar.svg");
export const MCalendarW = require("./newIcon/fi-rr-calendarW.svg");
export const MEvent = require("./newIcon/fi-rr-coffee-black.svg");
export const MEventW = require("./newIcon/fi-rr-coffee-white.svg");
export const MBackupUnit = require("./newIcon/fi-rr-box.svg");
export const MBackupUnitW = require("./newIcon/fi-rr-boxW.svg");
export const MClaims = require("./newIcon/Frame-1839.svg");
export const MClaimsW = require("./newIcon/Frame-1839W.svg");
export const MCertificate = require("./newIcon/fi-rr-certificate.svg");
export const MCertificateW = require("./newIcon/fi-rr-certificateW.svg");
export const MCustomer = require("./newIcon/fi-rr-customer.svg");
export const MCustomerW = require("./newIcon/fi-rr-customerW.svg");
export const MCommit = require("./newIcon/fi-rr-chart-pie-black.svg");
export const MCommitW = require("./newIcon/fi-rr-chart-pie-white.svg");
export const MDistributor = require("./newIcon/buildingGrey.svg");
export const MDistributorW = require("./newIcon/buildingWhite.svg");
export const MLeaves = require("./newIcon/Vector-black.svg");
export const MLeavesW = require("./newIcon/Vector-white.svg");
export const MProduct = require("./newIcon/fi-rr-e-learning.svg");
export const MProductW = require("./newIcon/fi-rr-e-learning-white.svg");
export const MBrand = require("./newIcon/fi-rr-cube.svg");
export const MBrandW = require("./newIcon/fi-rr-cube-white.svg");
export const MSolution = require("./newIcon/fi-rr-chart-network.svg");
export const MSolutionW = require("./newIcon/fi-rr-chart-network-white.svg");

// Icon Dashboard
// Statistic
export const CommitS = require("./newIcon/fi-rr-chart-pie.svg");
export const ChartS = require("./newIcon/fi-rr-chart-histogram.svg");
export const PipelineS = require("./newIcon/Frame-1838-purple.svg");
// Commit
export const MinGap = require("./newIcon/Group-1959-red.svg");
export const PlusGap = require("./newIcon/Group-1959-green.svg");
export const FailCommit = require("./newIcon/Group-1961.svg");
//  Card SO,PO,DO, & Invoice
export const SOIcon = require("./newIcon/fi-rr-sales-order-green-tosca.svg");
export const POIcon = require("./newIcon/fi-rr-purchase-order-green.svg");
export const PrintBlue = require("./newIcon/fi-rr-print.svg");
export const DOIcon = require("./newIcon/fi-rr-truck-side-red.svg");
export const InvoiceIcon = require("./newIcon/fi-rr-receipt-purple.svg");
export const EditBlack = require("./newIcon/Edit.svg");
// Event
export const ECalendar = require("./newIcon/Frame-1884-calendar-purple.svg");
export const EPin = require("./newIcon/Frame-1885-loc-purple.svg");
export const ArrowRight = require("./newIcon/fi-rr-angle-small-right.svg");
// Project
export const PCalendar = require("./newIcon/calendar-setdate.svg");
export const ArrowRightBl = require("./newIcon/fi-rr-arrow-right-blue.svg");
// Dashboard Engineer
export const Remarks = require("./newIcon/fi-rr-comment.svg");

// User
export const Key = require("./newIcon/fi-rr-key.svg");

// Pipeline
export const Hot = require("./newIcon/fi-rr-sun.svg");
export const Cold = require("./newIcon/fi-rr-snowflake.svg");
export const Onprogress = require("./newIcon/Frame-1838-orange.svg");
export const Win = require("./newIcon/fi-rr-gem.svg");
export const Lose = require("./newIcon/fi-rr-thumbs-down.svg");
export const Invalid = require("./newIcon/fi-rr-shield-interrogation.svg");
export const PICBl = require("./newIcon/fi-rr-comment-user-blue.svg");
export const Company = require("./newIcon/fi-rr-id-badge-blue.svg");
export const PipeDetail = require("./newIcon/Frame-1838-blue.svg");
export const Document = require("./newIcon/fi-rr-document-signed.svg");
export const DocumentB = require("./newIcon/fi-rr-document-signed-blue.svg");
export const Out = require("./newIcon/Vector.svg");

// Sales Target
export const Bronze = require("./newIcon/Group-2802-bronze.svg");
export const Silver = require("./newIcon/Group-2806-silver.svg");
export const Gold = require("./newIcon/Group-2804-gold.svg");
export const Platinum = require("./newIcon/Group-26897-platinum.svg");
export const Diamond = require("./newIcon/Group-2808-diamond.svg");

// Sales Order
export const NavbarSO = require("./newIcon/fi-rr-sales-order.svg");
export const NavbarSOB = require("./newIcon/fi-rr-sales-order-blue.svg");
export const TabBarPOBl = require("./newIcon/fi-rr-purchase-order-blue.svg");
export const TabBarDOBl = require("./newIcon/fi-rr-truck-side-blue.svg");
export const TabBarInvoiceBl = require("./newIcon/fi-rr-receipt-blue.svg");
export const GeneralUpdateBlack = require("./newIcon/fi-rr-comment-alt.svg");
export const GeneralUpdateB = require("./newIcon/fi-rr-comment-alt-blue.svg");

// Delivery Order
// export const Pdf = require("./newIcon/pdf.svg");
export const Pdf = require("../icons/pdf.png");
export const Doc = require("../icons/doc.svg");
export const Xls = require("../icons/xls.svg");
export const FilePdf = require("../Group-26984-pdf.png");

// Service
export const ServiceB = require("./newIcon/Frame-1838-service-blue.svg");
export const GeneralInfo = require("./newIcon/general-update.svg");
export const GeneralInfoBl = require("./newIcon/general-update-blue.svg");
export const Trumpet = require("./newIcon/fi-sr-megaphone.svg");
export const ServiceSumm = require("./newIcon/Frame-1838-service-purple.svg");
export const OnProgress = require("./newIcon/fi-rr-hourglass-end.svg");
export const Finish = require("./newIcon/fi-rr-shield-check.svg");
export const InstallationIcon = require("./newIcon/fi-rr-network.svg");
export const InstallationIconBl = require("./newIcon/fi-rr-network-blue.svg");
export const MaintenanceIcon = require("./newIcon/fi-rr-refresh.svg");
export const MaintenanceIconBl = require("./newIcon/fi-rr-refresh-blue.svg");
export const DetailDoc = require("./newIcon/fi-rr-file-add.svg");
export const EmptyDocument = require("./newIcon/fi-rr-document-signed-empty.svg");

// Calendar
export const Profile = require("./newIcon/Profile.svg");
export const Location = require("./newIcon/Location.svg");

// Claims
export const CEdit = require("./newIcon/fi-rr-edit.svg");
export const CTrash = require("./newIcon/fi-rr-trash.svg");
export const EmptyImageClaim = require("./newIcon/empty.svg");
export const UndoClaim = require("./newIcon/undo.svg");

// Profile
// Profile Sales
export const CustomerRec = require("./newIcon/fi-rr-following-bluesky.svg");
export const Plus = require("./newIcon/fi-rr-plus-blue.svg");
// Profile Engineer
export const Upload = require("./newIcon/fi-rr-upload-blue.svg");
export const TrashRed = require("./newIcon/fi-rr-trash-red.svg");

// Certificate
export const EmptyImage = require("./newIcon/fi-rr-picture.png");
export const CertificateGrey = require("./newIcon/fi-rr-diploma-gray.svg");
export const jpg = require("./newIcon/Group-jpg.svg");
export const pdfCertificate = require("./newIcon/Frame-pdf.svg");

// Notifications
export const NotifTarget = require("./newIcon/fi-rr-chat-arrow-grow-blue.svg");
export const NotifClaims = require("./newIcon/Frame-1839-blue.svg");
export const NotifCertificate = require("./newIcon/fi-rr-diploma.svg");

// Customer
export const MarkerBlue = require("./newIcon/fi-rr-marker-blue.svg");
export const EditBlue = require("./newIcon/fi-rr-edit-blue.svg");

// Icon In Any Page
export const Achievement = require("./newIcon/fi-rr-trophy.svg");
export const AchievementW = require("./newIcon/fi-rr-trophy-white.svg");
export const BellNotif = require("./newIcon/fi-rr-bell.svg");
export const CustRecord = require("./newIcon/fi-rr-folder.svg");
export const CustRecordW = require("./newIcon/fi-rr-folder-white.svg");
export const Logout = require("./newIcon/fi-rr-power.svg");
export const LogoutW = require("./newIcon/fi-rr-power-white.svg");
export const Eye = require("./newIcon/fi-rr-eye.svg");
export const EyeCrossed = require("./newIcon/fi-rr-eye-crossed.svg");
export const ChecklistGreen = require("./newIcon/circle-check-full.svg");
export const CheckFull = require("../Mask-group.png");
export const CircleGreen = require("./newIcon/Ellipse-630.svg");
export const IDCard = require("./newIcon/id_card.svg");
export const PIC = require("./newIcon/pic.svg");
export const GearSettingBlue = require("./newIcon/gearSettingBlue.svg");
export const GearSettingGrey = require("./newIcon/gearSettingGrey.svg");
export const InfoSquareBlue = require("./newIcon/infoSquareBlue.svg");
export const InfoSquareGrey = require("./newIcon/infoSquareGrey.svg");
export const MarkerBlueOutline = require("./newIcon/markerBlue.svg");
// export const refresh = require("./newIcon/fi-rr-refresh.svg")
export const iconBgHot = require("./newIcon/Ellipse-631.svg");
export const ArrowBreadcrumb = require("./newIcon/icon.svg");
export const EditWhite = require("./newIcon/fi-rr-edit-white.svg");
export const HotAmount = require("./newIcon/Intersect-hot.svg");
export const ColdAmount = require("./newIcon/Intersect-cold.svg");
export const SquareForgotPw1 = require("./newIcon/Group-27088.svg");
export const SquareForgotPw2 = require("./newIcon/Group-27089.svg");
export const DangerRed = require("./newIcon/fi-rr-ban.svg");

// Icon Module Invoice
export const iconInvoice1 = require("./newIcon/icon-invoice-1.svg");
export const iconInvoice2 = require("./newIcon/icon-invoice-2.svg");
export const iconInvoice3 = require("./newIcon/icon-invoice-3.svg");
export const iconInvoice4 = require("./newIcon/icon-invoice-4.svg");
export const iconInvoice5 = require("./newIcon/icon-invoice-5.svg");
export const ArrowDownGreen = require("./newIcon/fi-rr-angle-small-down-green.svg");

// Icon Module Leaves
export const Leave = require("./newIcon/Vector-purple.svg");
export const LeaveBlue = require("./newIcon/leavesBlue.svg");
export const LeaveNotif = require("./newIcon/Vector-purple.svg");
export const InfoMark = require("./newIcon/fi-sr-interrogation.svg");
export const XBlack = require("./newIcon/x-black.svg");
export const DefaultProfile = require("../default_profile.png");
export const InActiveUser = require("./newIcon/fi-rr-user-active.svg");
export const ShortClaim = require("./newIcon/short-claims.svg");
export const InfoBlue = require("./newIcon/fi-rr-info-blue.svg");
export const UserMoveBlue = require("./newIcon/fi-rr-user-move-blue.svg");
export const UserMoveBlack = require("./newIcon/fi-rr-user-move-black.svg");
export const XDanger = require("./IconAction/x-danger.svg");
export const CheckGreenLine = require("./IconAction/check-green.svg");
export const LoanApproved = require("./IconNotification/loan-approved.svg");
export const LoanRejected = require("./IconNotification/loan-rejected.svg");
export const LoanSubmitted = require("./IconNotification/loan-submitted.svg");
export const ArrowRightBlue = require("./newIcon/fi-rr-angle-small-right-blue.svg");
export const DefaultImage = require("./newIcon/image default.svg");
export const DefaultImageLandscape = require("./newIcon/image default - landscape.svg");
export const CrashImagePortrait = require("./newIcon/image broken.svg");
export const CrashImageLandscape = require("./newIcon/image broken - landscape.svg");
export const UploadGreen = require("./newIcon/fi-rr-upload-green.svg");
export const DefaultMenuOff = require("./newIcon/fi-rr-duplicate-black.svg");
export const DefaultMenuOn = require("./newIcon/fi-rr-duplicate-white.svg");
export const SettingBlue = require("./newIcon/setting-blue.svg");
export const CircleOutlineBlack = require("./newIcon/Frame 2625.svg");
export const CircleOutlineWhite = require("./newIcon/Frame 2625 (1).svg");
export const DefaultProfilePortrait = require("./newIcon/profile image default.svg");
export const DefaultProfileLandscape = require("./newIcon/profile image default - landscape.svg");
export const EditNotes = require("./IconAction/edit-notes.svg");
export const SOGreen = require("./newIcon/fi-rr-sales-order-green.svg");
export const DolarPurple = require("./newIcon/Frame-1839-purple.svg");
export const DefaultProfileGrey = require("./profile-grey.svg");
export const BlockUser = require("./newIcon/fi-rr-user-block.svg");
export const UnblockUser = require("./newIcon/fi-rr-user-unblock.svg");
export const DefaultPNG = require("./newIcon/defaul-png.svg");
export const IconFake = require("./icon-fake.svg");
export const EmptyVisit = require("../empty-visit.png");
export const IconViewMap = require("./newIcon/icon-view-map.svg");
