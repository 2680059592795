import React from "react";
import { Card } from "reactstrap";

export default function Loading() {
  return (
    <Card
      style={{
        height: "100%",
        width: "100%",
        position: "absolute",
        top: 0,
        bottom: 0,
        zIndex: 9999,
        background: "rgba(225, 225, 225, 0.3)", // background: "red",
        // opacity: 8,
      }}
    >
      <div style={{ margin: "auto" }}>
        <div className="spinner-grow text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
        <div className="spinner-grow text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
        <div className="spinner-grow text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </Card>
  );
}
