import React from "react";
import * as Icon from "react-feather";
import {
  MBackupUnit,
  MBackupUnitW,
  MCalendar,
  MCalendarW,
  MCertificate,
  MCertificateW,
  MClaims,
  MClaimsW,
  MCommit,
  MCommitW,
  MCustomer,
  MCustomerW,
  MDashboard,
  MDashboardWhite,
  MEvent,
  MEventW,
  MGroup,
  MGroupW,
  MNavbarDO,
  MNavbarDOW,
  MNavbarInvoice,
  MNavbarInvoiceW,
  MNavbarPO,
  MNavbarPOW,
  MNavbarSO,
  MNavbarSOW,
  MPipeline,
  MPipelineW,
  MRole,
  MRoleW,
  MSalesTarget,
  MSalesTargetW,
  MService,
  MServiceW,
  MUser,
  MUserW,
  MDistributor,
  MDistributorW,
  MLeaves,
  MLeavesW,
  MProduct,
  MProductW,
  MBrand,
  MBrandW,
  MSolution,
  MSolutionW,
} from "../assets/img/svg";

const navigationConfig = [
  // Dashboard
  {
    id: "dashboardSuperAdmin",
    title: "Dashboard",
    type: "item",
    icon: (
      <img
        className="mr-1"
        src={MDashboard}
        alt="Dashboard"
        width={18}
        height={18}
      />
    ),
    iconActive: (
      <img
        className="mr-1"
        src={MDashboardWhite}
        alt="Dashboard"
        width={18}
        height={18}
      />
    ),
    permissions: ["superadmin"],
    navLink: "/superadmin/dashboard",
  },
  {
    id: "dashboardEngineer",
    title: "Dashboard",
    type: "item",
    icon: (
      <img
        className="mr-1"
        src={MDashboard}
        alt="Dashboard"
        width={18}
        height={18}
      />
    ),
    iconActive: (
      <img
        className="mr-1"
        src={MDashboardWhite}
        alt="Dashboard"
        width={18}
        height={18}
      />
    ),
    permissions: ["engineer"],
    navLink: "/engineer/dashboard",
  },
  {
    id: "dashboardLeadEngineer",
    title: "Dashboard",
    type: "item",
    icon: (
      <img
        className="mr-1"
        src={MDashboard}
        alt="Dashboard"
        width={18}
        height={18}
      />
    ),
    iconActive: (
      <img
        className="mr-1"
        src={MDashboardWhite}
        alt="Dashboard"
        width={18}
        height={18}
      />
    ),
    permissions: ["lead-engineer"],
    navLink: "/lead-engineer/dashboard",
  },
  {
    id: "dashboardSales",
    title: "Dashboard",
    type: "item",
    icon: (
      <img
        className="mr-1"
        src={MDashboard}
        alt="Dashboard"
        width={18}
        height={18}
      />
    ),
    iconActive: (
      <img
        className="mr-1"
        src={MDashboardWhite}
        alt="Dashboard"
        width={18}
        height={18}
      />
    ),
    permissions: ["sales"],
    navLink: "/sales/dashboard",
  },
  {
    id: "dashboardTLSales",
    title: "Dashboard",
    type: "item",
    icon: (
      <img
        className="mr-1"
        src={MDashboard}
        alt="Dashboard"
        width={18}
        height={18}
      />
    ),
    iconActive: (
      <img
        className="mr-1"
        src={MDashboardWhite}
        alt="Dashboard"
        width={18}
        height={18}
      />
    ),
    permissions: ["lead-sales"],
    navLink: "/lead-sales/dashboard",
  },
  {
    id: "dashboardAdmin",
    title: "Dashboard",
    type: "item",
    icon: (
      <img
        className="mr-1"
        src={MDashboard}
        alt="Dashboard"
        width={18}
        height={18}
      />
    ),
    iconActive: (
      <img
        className="mr-1"
        src={MDashboardWhite}
        alt="Dashboard"
        width={18}
        height={18}
      />
    ),
    permissions: ["admin", "admin-purchase"],
    navLink: "/admin-purchase/dashboard",
  },
  {
    id: "dashboardAdminEngineer",
    title: "Dashboard",
    type: "item",
    icon: (
      <img
        className="mr-1"
        src={MDashboard}
        alt="Dashboard"
        width={18}
        height={18}
      />
    ),
    iconActive: (
      <img
        className="mr-1"
        src={MDashboardWhite}
        alt="Dashboard"
        width={18}
        height={18}
      />
    ),
    permissions: ["admin", "admin-engineer"],
    navLink: "/admin-engineer/dashboard",
  },
  {
    id: "dashboardAdminSales",
    title: "Dashboard",
    type: "item",
    icon: (
      <img
        className="mr-1"
        src={MDashboard}
        alt="Dashboard"
        width={18}
        height={18}
      />
    ),
    iconActive: (
      <img
        className="mr-1"
        src={MDashboardWhite}
        alt="Dashboard"
        width={18}
        height={18}
      />
    ),
    permissions: ["admin", "admin-sales"],
    navLink: "/admin-sales/dashboard",
  },
  {
    id: "dashboardAdminFinance",
    title: "Dashboard",
    type: "item",
    icon: (
      <img
        className="mr-1"
        src={MDashboard}
        alt="Dashboard"
        width={18}
        height={18}
      />
    ),
    iconActive: (
      <img
        className="mr-1"
        src={MDashboardWhite}
        alt="Dashboard"
        width={18}
        height={18}
      />
    ),
    permissions: ["admin", "admin-finance"],
    navLink: "/admin-finance/dashboard",
  },
  {
    id: "dashboardCourier",
    title: "Dashboard",
    type: "item",
    icon: (
      <img
        className="mr-1"
        src={MDashboard}
        alt="Dashboard"
        width={18}
        height={18}
      />
    ),
    iconActive: (
      <img
        className="mr-1"
        src={MDashboardWhite}
        alt="Dashboard"
        width={18}
        height={18}
      />
    ),
    permissions: ["courier"],
    navLink: "/courier/dashboard",
  },

  // USER
  {
    id: "user",
    title: "",
    type: "groupHeader",
    groupTitle: "USER",
    navLink: "#",
    permissions: [
      "engineer",
      "lead-engineer",
      "lead-sales",
      "sales",
      "superadmin",
      "admin-engineer",
      "admin-finance",
      "admin-purchase",
      "admin-sales",
    ],
  },
  {
    id: "groupSuperAdmin",
    title: "Group",
    type: "item",
    icon: (
      <img className="mr-1" src={MGroup} alt="Group" width={18} height={18} />
    ),
    iconActive: (
      <img className="mr-1" src={MGroupW} alt="Group" width={18} height={18} />
    ),
    permissions: ["superadmin", "sales", "engineer"],
    navLink: "/group",
  },
  {
    id: "groupTL",
    title: "Group",
    type: "item",
    icon: (
      <img className="mr-1" src={MGroup} alt="Group" width={18} height={18} />
    ),
    iconActive: (
      <img className="mr-1" src={MGroupW} alt="Group" width={18} height={18} />
    ),
    permissions: ["lead-sales", "lead-engineer"],
    navLink: "/group-lead",
  },

  {
    id: "roleSuperAdmin",
    title: "Role",
    type: "item",
    icon: (
      <img className="mr-1" src={MRole} alt="Role" width={18} height={18} />
    ),
    iconActive: (
      <img className="mr-1" src={MRoleW} alt="Role" width={18} height={18} />
    ),
    permissions: ["superadmin"],
    navLink: "/role",
  },
  {
    id: "userSuperAdmin",
    title: "User",
    type: "item",
    icon: (
      <img className="mr-1" src={MUser} alt="User" width={18} height={18} />
    ),
    iconActive: (
      <img className="mr-1" src={MUserW} alt="User" width={18} height={18} />
    ),
    permissions: ["superadmin"],
    navLink: "/user",
  },
  {
    id: "customerSuperAdmin",
    title: "Customer",
    type: "item",
    icon: (
      <img className="mr-1" src={MCustomer} alt="User" width={18} height={18} />
    ),
    iconActive: (
      <img
        className="mr-1"
        src={MCustomerW}
        alt="User"
        width={18}
        height={18}
      />
    ),
    permissions: ["superadmin"],
    navLink: "/customer-all",
  },
  {
    id: "distributorAdmin",
    title: "Distributor",
    type: "item",
    icon: (
      <img
        className="mr-1"
        src={MDistributor}
        alt="distributor"
        width={18}
        height={18}
      />
    ),
    iconActive: (
      <img
        className="mr-1"
        src={MDistributorW}
        alt="distributor"
        width={18}
        height={18}
      />
    ),
    permissions: [
      "superadmin",
      "admin-engineer",
      "admin-finance",
      "admin-purchase",
      "admin-sales",
    ],
    navLink: "/distributor-all",
  },
  {
    id: "distributorSales",
    title: "Distributor",
    type: "item",
    icon: (
      <img
        className="mr-1"
        src={MDistributor}
        alt="distributor"
        width={18}
        height={18}
      />
    ),
    iconActive: (
      <img
        className="mr-1"
        src={MDistributorW}
        alt="distributor"
        width={18}
        height={18}
      />
    ),
    permissions: ["lead-sales", "sales"],
    navLink: "/distributor",
  },
  {
    id: "certificateSuperAdmin",
    title: "Certificate",
    type: "item",
    icon: (
      <img
        className="mr-1"
        src={MCertificate}
        alt="User"
        width={18}
        height={18}
      />
    ),
    iconActive: (
      <img
        className="mr-1"
        src={MCertificateW}
        alt="User"
        width={18}
        height={18}
      />
    ),
    permissions: ["superadmin"],
    navLink: "/certificate-all",
  },

  // Sales
  {
    id: "sales",
    title: "",
    type: "groupHeader",
    groupTitle: "SALES",
    navLink: "#",
    permissions: [
      "admin-engineer",
      "admin-finance",
      "admin-purchase",
      // "engineer",
      // "lead-engineer",
      "superadmin",
      "lead-sales",
      "sales",
      "admin-sales",
    ],
  },
  {
    id: "TargetLeadSales",
    title: "Target Group",
    type: "item",
    icon: (
      <img
        className="mr-1"
        src={MSalesTarget}
        alt="SalesTarget"
        width={18}
        height={18}
      />
    ),
    iconActive: (
      <img
        className="mr-1"
        src={MSalesTargetW}
        alt="SalesTarget"
        width={18}
        height={18}
      />
    ),
    permissions: ["lead-sales"],
    navLink: "/sales-target-lead",
  },
  {
    id: "commit",
    title: "Commit",
    type: "item",
    icon: (
      <img className="mr-1" src={MCommit} alt="Commit" width={18} height={18} />
    ),
    iconActive: (
      <img
        className="mr-1"
        src={MCommitW}
        alt="Commit"
        width={18}
        height={18}
      />
    ),
    permissions: ["superadmin"],
    navLink: "/commit",
  },
  {
    id: "pipelineSales",
    title: "Pipeline",
    type: "item",
    icon: (
      <img
        className="mr-1"
        src={MPipeline}
        alt="Pipeline"
        width={18}
        height={18}
      />
    ),
    iconActive: (
      <img
        className="mr-1"
        src={MPipelineW}
        alt="Pipeline"
        width={18}
        height={18}
      />
    ),
    permissions: [
      "superadmin",
      "admin-engineer",
      "admin-finance",
      "admin-sales",
      "sales",
    ],
    navLink: "/pipeline",
  },
  {
    id: "pipelineLeadSales",
    title: "Pipeline",
    type: "item",
    icon: (
      <img
        className="mr-1"
        src={MPipeline}
        alt="Pipeline"
        width={18}
        height={18}
      />
    ),
    iconActive: (
      <img
        className="mr-1"
        src={MPipelineW}
        alt="Pipeline"
        width={18}
        height={18}
      />
    ),
    permissions: ["lead-sales"],
    navLink: "/pipeline-lead",
  },
  {
    id: "SalesOrder",
    title: "SalesOrder",
    type: "collapse",
    icon: (
      <img
        className="mr-1"
        src={MNavbarSO}
        alt="SalesOrder"
        width={18}
        height={18}
      />
    ),
    iconActive: (
      <img
        className="mr-1"
        src={MNavbarSOW}
        alt="SalesOrder"
        width={18}
        height={18}
      />
    ),
    children: [
      {
        id: "sales-order",
        title: "Sales Order",
        type: "item",
        icon: <Icon.Circle size={12} />,
        iconActive: <Icon.Circle size={12} />,
        permissions: [
          "admin-engineer",
          "admin-finance",
          "admin-purchase",
          "admin-sales",
          // "engineer",
          // "lead-engineer",
          "lead-sales",
          "sales",
          "superadmin",
        ],
        navLink: "/sales-order",
      },
      {
        id: "po",
        title: "Total Selling",
        type: "item",
        icon: <Icon.Circle size={12} />,
        iconActive: <Icon.Circle size={12} />,
        permissions: ["superadmin"],
        navLink: "/po",
      },
      {
        id: "margin-sales",
        title: "Margin Sales",
        type: "item",
        icon: <Icon.Circle size={12} />,
        iconActive: <Icon.Circle size={12} />,
        permissions: ["superadmin"],
        navLink: "/margin-selling",
      },
      {
        id: "cost-engineer",
        title: "Cost Engineer",
        type: "item",
        icon: <Icon.Circle size={12} />,
        iconActive: <Icon.Circle size={12} />,
        permissions: ["superadmin"],
        navLink: "/cost-engineer",
      },
      {
        id: "bonus",
        title: "Bonus",
        type: "item",
        icon: <Icon.Circle size={12} />,
        iconActive: <Icon.Circle size={12} />,
        permissions: ["superadmin"],
        navLink: "/bonus",
      },
    ],
  },
  {
    id: "PurchaseOrder",
    title: "PurchaseOrder",
    type: "item",
    icon: (
      <img
        className="mr-1"
        src={MNavbarPO}
        alt="PurchaseOrder"
        width={18}
        height={18}
      />
    ),
    iconActive: (
      <img
        className="mr-1"
        src={MNavbarPOW}
        alt="PurchaseOrder"
        width={18}
        height={18}
      />
    ),
    permissions: [
      "superadmin",
      "lead-sales",
      "sales",
      "admin-finance",
      "admin-purchase",
      "admin-sales",
    ],
    navLink: "/purchase-order",
  },
  {
    id: "DeliveryOrder",
    title: "DeliveryOrder",
    type: "item",
    icon: (
      <img
        className="mr-1"
        src={MNavbarDO}
        alt="DeliveryOrder"
        width={18}
        height={18}
      />
    ),
    iconActive: (
      <img
        className="mr-1"
        src={MNavbarDOW}
        alt="DeliveryOrder"
        width={18}
        height={18}
      />
    ),
    permissions: [
      "admin-finance",
      "admin-purchase",
      "admin-sales",
      "superadmin",
      "lead-sales",
      "sales",
    ],
    navLink: "/delivery-order",
  },
  {
    id: "invoice",
    title: "Invoice",
    type: "item",
    icon: (
      <img
        className="mr-1"
        src={MNavbarInvoice}
        alt="invoice"
        width={18}
        height={18}
      />
    ),
    iconActive: (
      <img
        className="mr-1"
        src={MNavbarInvoiceW}
        alt="invoice"
        width={18}
        height={18}
      />
    ),
    permissions: [
      "admin-finance",
      "admin-purchase",
      "admin-sales",
      "superadmin",
      "lead-sales",
      "sales",
    ],
    navLink: "/invoice",
  },

  // Engineer
  {
    id: "engineer",
    title: "",
    type: "groupHeader",
    groupTitle: "ENGINEER",
    navLink: "#",
    permissions: [
      "superadmin",
      "lead-engineer",
      "engineer",
      "admin-engineer",
      "lead-sales",
      "sales",
      "admin-sales",
      "admin-finance",
      "admin-purchase",
    ],
  },
  {
    id: "service",
    title: "Service",
    type: "item",
    icon: (
      <img
        className="mr-1"
        src={MService}
        alt="service"
        width={18}
        height={18}
      />
    ),
    iconActive: (
      <img
        className="mr-1"
        src={MServiceW}
        alt="service"
        width={18}
        height={18}
      />
    ),
    permissions: [
      "admin",
      "admin-engineer",
      "engineer",
      "lead-engineer",
      "superadmin",
      "lead-sales",
      "sales",
    ],
    navLink: "/service",
  },
  {
    id: "product_specialization",
    title: "Product Specialization",
    type: "item",
    icon: (
      <img
        className="mr-1"
        src={MService}
        alt="service"
        width={18}
        height={18}
      />
    ),
    iconActive: (
      <img
        className="mr-1"
        src={MServiceW}
        alt="service"
        width={18}
        height={18}
      />
    ),
    permissions: [
      "admin",
      "admin-finance",
      "admin-engineer",
      "admin-purchase",
      "admin-sales",
      "superadmin",
    ],
    navLink: "/product-engineer",
  },

  // Product

  {
    id: "product",
    title: "",
    type: "groupHeader",
    groupTitle: "PRODUCT",
    navLink: "#",
    permissions: [
      "superadmin",
      "admin",
      "admin-engineer",
      "admin-finance",
      "admin-purchase",
      "admin-sales",
      "engineer",
      "lead-engineer",
      "lead-sales",
      "sales",
    ],
  },
  {
    id: "product",
    title: "Product Knowledge",
    type: "item",
    icon: (
      <img
        className="mr-1"
        src={MProduct}
        alt="Product"
        width={18}
        height={18}
      />
    ),
    iconActive: (
      <img
        className="mr-1"
        src={MProductW}
        alt="Product"
        width={18}
        height={18}
      />
    ),
    permissions: [
      "superadmin",
      "admin",
      "admin-engineer",
      "admin-finance",
      "admin-purchase",
      "admin-sales",
      "engineer",
      "lead-engineer",
      "lead-sales",
      "sales",
    ],
    navLink: "/product-knowledge",
  },
  {
    id: "masterBrand",
    title: "Master Brand",
    type: "item",
    icon: (
      <img className="mr-1" src={MBrand} alt="brand" width={18} height={18} />
    ),
    iconActive: (
      <img className="mr-1" src={MBrandW} alt="brand" width={18} height={18} />
    ),
    permissions: [
      "superadmin",
      "admin",
      "admin-engineer",
      "admin-finance",
      "admin-purchase",
      "admin-sales",
    ],
    navLink: "/master-brand",
  },
  {
    id: "masterSolution",
    title: "Master Solution",
    type: "item",
    icon: (
      <img
        className="mr-1"
        src={MSolution}
        alt="solution"
        width={18}
        height={18}
      />
    ),
    iconActive: (
      <img
        className="mr-1"
        src={MSolutionW}
        alt="solution"
        width={18}
        height={18}
      />
    ),
    permissions: [
      "superadmin",
      "admin",
      "admin-engineer",
      "admin-finance",
      "admin-purchase",
      "admin-sales",
    ],
    navLink: "/master-solution",
  },

  // General
  {
    id: "general",
    title: "",
    type: "groupHeader",
    groupTitle: "GENERAL",
    navLink: "#",
    permissions: [
      "admin",
      "admin-engineer",
      "admin-finance",
      "admin-purchase",
      "admin-sales",
      "engineer",
      "lead-engineer",
      "lead-sales",
      "sales",
      "superadmin",
      "courier",
    ],
  },
  {
    id: "salesTargetSuperAdmin",
    title: "Sales Target",
    type: "item",
    icon: (
      <img
        className="mr-1"
        src={MSalesTarget}
        alt="SalesTarget"
        width={18}
        height={18}
      />
    ),
    iconActive: (
      <img
        className="mr-1"
        src={MSalesTargetW}
        alt="SalesTarget"
        width={18}
        height={18}
      />
    ),
    permissions: ["superadmin"],
    navLink: "/sales-target",
  },
  {
    id: "calenderActivity",
    title: "Calender Activity",
    type: "item",
    icon: (
      <img
        className="mr-1"
        src={MCalendar}
        alt="calendar"
        width={18}
        height={18}
      />
    ),
    iconActive: (
      <img
        className="mr-1"
        src={MCalendarW}
        alt="backup-unit"
        width={18}
        height={18}
      />
    ),
    permissions: [
      "admin",
      "admin-engineer",
      "admin-finance",
      "admin-purchase",
      "admin-sales",
      "engineer",
      "lead-engineer",
      "lead-sales",
      "sales",
      "superadmin",
      "courier",
    ],
    navLink: "/calender-activity",
  },
  {
    id: "event",
    title: "Event",
    type: "item",
    icon: (
      <img className="mr-1" src={MEvent} alt="Event" width={18} height={18} />
    ),
    iconActive: (
      <img className="mr-1" src={MEventW} alt="Event" width={18} height={18} />
    ),
    permissions: [
      "superadmin",
      "admin-engineer",
      "admin-finance",
      "admin-purchase",
      "admin-sales",
      "engineer",
      "lead-engineer",
      "lead-sales",
      "sales",
      "courier",
    ],
    navLink: "/event",
  },
  {
    id: "backupUnit",
    title: "Backup Unit",
    type: "item",
    icon: (
      <img
        className="mr-1"
        src={MBackupUnit}
        alt="backup-unit"
        width={18}
        height={18}
      />
    ),
    iconActive: (
      <img
        className="mr-1"
        src={MBackupUnitW}
        alt="backup-unit"
        width={18}
        height={18}
      />
    ),
    permissions: [
      "admin-finance",
      "admin-purchase",
      "admin-sales",
      "superadmin",
      "engineer",
      "lead-engineer",
      "lead-sales",
      "sales",
      "admin-engineer",
    ],
    navLink: "/backup-unit",
  },
  {
    id: "leaves",
    title: "Leaves",
    type: "collapse",
    icon: (
      <img className="mr-1" src={MLeaves} alt="Leaves" width={18} height={18} />
    ),
    iconActive: (
      <img
        className="mr-1"
        src={MLeavesW}
        alt="Leaves"
        width={18}
        height={18}
      />
    ),
    children: [
      {
        id: "leave-approval",
        title: "Leaves Approval",
        type: "item",
        icon: <Icon.Circle size={12} />,
        iconActive: <Icon.Circle size={12} />,
        permissions: [
          // "admin-finance",
          // "lead-engineer",
          // "lead-sales",
          "admin-sales",
          "superadmin",
        ],
        navLink: "/cuti-approve",
      },
      {
        id: "leave-apply",
        title: "Leaves",
        type: "item",
        icon: <Icon.Circle size={12} />,
        iconActive: <Icon.Circle size={12} />,
        permissions: [
          "admin",
          "admin-engineer",
          "admin-finance",
          "admin-purchase",
          "admin-sales",
          "engineer",
          "lead-engineer",
          "lead-sales",
          "sales",
          "courier",
          "superadmin",
        ],
        navLink: "/cuti-list",
      },
      {
        id: "leave-type",
        title: "Leaves Type",
        type: "item",
        icon: <Icon.Circle size={12} />,
        iconActive: <Icon.Circle size={12} />,
        permissions: [
          // "admin-finance",
          // "lead-engineer",
          // "lead-sales",
          "superadmin",
        ],
        navLink: "/leave-type",
      },
      {
        id: "leave-list",
        title: "Leaves List",
        type: "item",
        icon: <Icon.Circle size={12} />,
        iconActive: <Icon.Circle size={12} />,
        permissions: [
          "superadmin",
          "admin-engineer",
          "admin-finance",
          "admin-purchase",
          "admin-sales",
        ],
        navLink: "/leave-list",
      },
    ],
  },
  {
    id: "menu",
    title: "Master Menu",
    type: "item",
    icon: (
      <img className="mr-1" src={MClaims} alt="Claims" width={18} height={18} />
    ),
    iconActive: (
      <img
        className="mr-1"
        src={MClaimsW}
        alt="Claims"
        width={18}
        height={18}
      />
    ),
    permissions: ["superadmin"],
    navLink: "/menu",
  },
  {
    id: "claims",
    title: "Claims",
    type: "collapse",
    icon: (
      <img className="mr-1" src={MClaims} alt="Claims" width={18} height={18} />
    ),
    iconActive: (
      <img
        className="mr-1"
        src={MClaimsW}
        alt="Claims"
        width={18}
        height={18}
      />
    ),
    children: [
      {
        id: "approval-claims",
        title: "Approval Claims",
        type: "item",
        icon: <Icon.Circle size={12} />,
        iconActive: <Icon.Circle size={12} />,
        permissions: [
          "admin-finance",
          "lead-engineer",
          "lead-sales",
          "superadmin",
          // "admin-sales",
        ],
        navLink: "/approval-claims",
      },
      {
        id: "list-claims",
        title: "List Claims",
        type: "item",
        icon: <Icon.Circle size={12} />,
        iconActive: <Icon.Circle size={12} />,
        permissions: [
          "admin",
          "admin-engineer",
          "admin-finance",
          "admin-purchase",
          "admin-sales",
          "engineer",
          "lead-engineer",
          "lead-sales",
          "sales",
          "courier",
          "superadmin",
        ],
        navLink: "/list-claims",
      },
      {
        id: "claims-terms",
        title: "Claim Terms",
        type: "item",
        icon: <Icon.Circle size={12} />,
        iconActive: <Icon.Circle size={12} />,
        permissions: ["admin-finance", "superadmin"],
        navLink: "/claim-terms",
      },
    ],
  },
];

export default navigationConfig;
