import React, { useEffect, useState } from "react";
import "../assets/scss/pages/running-text.scss";
import ImageUser from "./ImageUser";
import { getData } from "../api/controller/Controller";
import formatCurrency from "./formatCurrency";
import cn from "classnames";
import { AsyncStorage } from "reactjs-async-localstorage";

const RunningText = (props) => {
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [users, setusers] = useState([]);
  const [navbarSearch, setNavbarSearch] = useState(false);
  const halfwayIndex = Math.ceil(users.length / 2);
  const itemHeight = 10;
  // const itemHeight = document.getElementById("height-text")?.clientHeight;
  const shuffleThreshold = halfwayIndex * itemHeight;
  //   const visibleStyleThreshold = shuffleThreshold / 2;

  const determinePlacement = (itemIndex) => {
    if (currentIndex === itemIndex) return 0;

    if (itemIndex >= halfwayIndex) {
      if (currentIndex > itemIndex - halfwayIndex) {
        return (itemIndex - currentIndex) * itemHeight;
      } else {
        return -(users.length + currentIndex - itemIndex) * itemHeight;
      }
    }

    if (itemIndex > currentIndex) {
      return (itemIndex - currentIndex) * itemHeight;
    }

    if (itemIndex < currentIndex) {
      if ((currentIndex - itemIndex) * itemHeight >= shuffleThreshold) {
        return (users.length - (currentIndex - itemIndex)) * itemHeight;
      }
      return -(currentIndex - itemIndex) * itemHeight;
    }
  };

  const isExpired = (date) => {
    let updated_at = new Date(date);
    let currentDate = new Date();
    let timeDifference = currentDate - updated_at;
    let oneDayInMilliseconds = 24 * 60 * 60 * 1000;
    if (timeDifference < oneDayInMilliseconds) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    let sum_data = 0;
    getData(null, "sales_order/sales_above_1M").then((res) => {
      if (res.status === 200) {
        // let data = res.data.filter((v) => isExpired(v.pipeline.updated_at));
        sum_data = res.count > 1 ? res.count : res.count === 1 ? 2 : res.count;
        // data.length > 1 ? data.length : data.length === 1 ? 2 : data.length;
        setusers(res.data);
      }
    });
    const interval = setInterval(async () => {
      AsyncStorage.getItem("navbar_search").then((res) => {
        if (res) {
          let result = JSON.parse(res);
          if (typeof result == "boolean") {
            setNavbarSearch(result);
          }
        } else {
          setNavbarSearch(false);
        }
      });
      setCurrentIndex((prevIndex) =>
        prevIndex === sum_data - 1 ? 0 : prevIndex + 1
      );
    }, 6000);

    return () => {
      clearInterval(interval);
    };
    //   eslint-disable-next-line
  }, [navbarSearch]);

  return (
    <div className="vertical-running-text">
      {!navbarSearch && users.length > 0
        ? users.map((item, index) =>
            isExpired(item?.pipeline?.updated_at) ? (
              <div
                key={index}
                className={cn("vertical-slide", {
                  active: currentIndex === index,
                })}
                id="height-text"
                style={{
                  transform: `translateY(${determinePlacement(index)}px)`,
                  position: "absolute",
                  zIndex: currentIndex === index ? 1 : 0,
                  top: currentIndex === index ? 13 : -100,
                }}
              >
                <div className="d-flex align-items-center">
                  <div className="mr-1">
                    <ImageUser
                      datas={item.pipeline.user}
                      width={40}
                      height={40}
                    />
                  </div>
                  <div>
                    <span
                      style={{
                        color: "#464646",
                        fontSize: currentIndex !== index ? 12 : 14,
                        fontWeight: currentIndex !== index ? "" : "bold",
                      }}
                    >
                      {item.pipeline.user.name} melakukan penjualan
                      <span style={{ color: "#32A020" }}>
                        {" "}
                        {formatCurrency(item.total_selling)}!
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            ) : null
          )
        : null}
    </div>
  );
};

export default RunningText;
