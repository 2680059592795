import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { CrashImagePortrait } from "../../../../assets/img/svg";
import Truncate from "../../../../utility/Truncate";
import { SERVICEIMAGE } from "../../../../api/config/Config";
import { downloadFile } from "../../../../api/controller/Controller";
// import CheckBoxesVuexy from "../../../../components/@vuexy/checkbox/CheckboxesVuexy";
// import { Check } from "react-feather";
import heic2any from "heic2any";
import ComponentSpinner from "../../../../components/@vuexy/spinner/Loading-spinner";
import AsyncSelect from "react-select/async";

export const ModalShowImage = ({
  modal,
  image,
  name,
  errorImage,
  setErrorImage,
  cancel,
}) => {
  const [differentImage, setDifferentImage] = useState(null);
  const [loadImage, setLoadImage] = useState(false);
  const [loadModal, setLoadModal] = useState(false);
  const download = async (file) => {
    let data = file.includes("/storage/") ? file : `/storage/${file}`;
    if (data.includes(".heif") || data.includes(".heic")) {
      if (differentImage) {
        const link = document.createElement("a");
        link.href = differentImage;
        let result = name.replace(".heif", ".png").replace(".heic", ".png");
        link.setAttribute("download", result);
        document.body.appendChild(link);
        link.click();

        window.URL.revokeObjectURL(differentImage);
      }
    } else {
      try {
        let result = await downloadFile(
          { file: data },
          "download/download_file"
        );

        const blob = new Blob([result]);
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();

        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Terjadi kesalahan saat mengunduh file:", error);
      }
    }
  };

  const convertHeicOrHeif = async () => {
    let data = !image.includes("/storage/") ? "/storage/" + image : image;
    setLoadImage(true);
    setLoadModal(true);
    try {
      let result = await downloadFile({ file: data }, "download/preview_file");

      const blob = new Blob([result]);
      const convertedBlob = await heic2any({
        blob,
        toType: "image/png",
      });
      const convertedBlobUrl = window.URL.createObjectURL(convertedBlob);
      setDifferentImage(convertedBlobUrl);
      setLoadImage(false);
      setLoadModal(false);
    } catch (error) {
      setLoadImage(false);
      setLoadModal(false);
      setErrorImage(true);
    }
  };

  useEffect(() => {
    if (image.includes(".heic") || image.includes(".heif")) {
      if (modal) {
        convertHeicOrHeif();
      }
    }
    // eslint-disable-next-line
  }, [modal]);

  return (
    <Modal
      isOpen={modal}
      toggle={() => cancel()}
      className="modal-dialog-centered"
      style={{ width: "max-content" }}
      contentClassName="modal-view-image"
    >
      <ModalHeader />

      <ModalBody style={{ display: "contents" }}>
        {/* {!errorImage ? ( */}
        {loadModal ? <ComponentSpinner /> : null}
        <div className="position-relative">
          <div
            className="position-absolute d-flex p-1 justify-content-between w-100"
            style={{ backgroundColor: "rgba(52, 52, 52, 0.8)" }}
          >
            <div style={{ color: "#FFFFFF" }}>
              <Truncate length={20} text={name ? name : "no name"} />
            </div>
            <Button
              style={{
                padding: "6px 12px",
                borderRadius: 5,
                color: "#FFFFFF",
              }}
              color="light"
              outline
              onClick={(e) => {
                download(image);
              }}
            >
              Download
            </Button>
          </div>
        </div>
        {/* ) : null} */}
        {errorImage ? (
          <div className="p-3">
            <div className="my-2 mx-3">
              <img
                src={CrashImagePortrait}
                alt="attach"
                width={350}
                height={400}
                style={{ objectFit: "cover" }}
              />
            </div>
            <div className="text-center">
              <span className="font-weight-bold" style={{ color: "#464646" }}>
                File Not Found
              </span>
            </div>
          </div>
        ) : image.includes(".heic") || image.includes(".heif") ? (
          <img
            src={
              loadImage
                ? CrashImagePortrait
                : differentImage
                ? differentImage
                : CrashImagePortrait
            }
            alt="attach"
            width={loadImage || !differentImage ? 350 : "auto"}
            height={loadImage || !differentImage ? 400 : "auto"}
            style={{ objectFit: "cover" }}
            onError={(e) => {
              // e.currentTarget.src = CrashImagePortrait;
              setErrorImage(true);
              setDifferentImage(CrashImagePortrait);
            }}
          />
        ) : (
          <img
            src={image.includes("base64") ? image : SERVICEIMAGE + image}
            alt="attach"
            width={"auto"}
            height={"auto"}
            style={{ objectFit: "cover" }}
            onError={(e) => {
              // e.currentTarget.src = CrashImagePortrait;
              setErrorImage(true);
            }}
          />
        )}
      </ModalBody>
    </Modal>
  );
};

export const ModalPdf = ({ modal, pdf, cancel }) => {
  return (
    <Modal
      isOpen={modal}
      toggle={() => cancel()}
      className="modal-dialog-centered modal-lg"
      style={{ width: "min-content", display: "min-content" }}
    >
      <ModalHeader toggle={() => cancel()} />
      <ModalBody style={{ display: "contents" }}>
        <iframe
          style={{
            width: "700px",
            height: "700px",
            borderWidth: 0,
            objectFit: "contain",
            objectPosition: "center",
          }}
          src={`https://view.officeapps.live.com/op/view.aspx?src=${`${SERVICEIMAGE}/storage/${pdf}`}`}
          type="application/pdf"
          title="title"
        />
      </ModalBody>
    </Modal>
  );
};

export const ModalChooseCategory = ({
  modal,
  data,
  state,
  onChange,
  options,
  cancel,
  submit,
}) => {
  return (
    <Modal
      isOpen={modal}
      toggle={() => cancel()}
      className="modal-dialog-centered modal-lg"
    >
      <ModalHeader toggle={() => cancel()} tag={"div"}>
        <span className="font-weight-bold">Choose Claim Category</span>
      </ModalHeader>
      <ModalBody>
        <Row>
          {/* {data.length > 0 && state.length > 0
            ? data.map((item, index) => (
                <Col
                  key={`category_claim_${index}`}
                  xs="12"
                  sm="12"
                  md="4"
                  lg="4"
                  xl="4"
                >
                  <CheckBoxesVuexy
                    color="success"
                    icon={<Check className="vx-icon" size={16} />}
                    label={item.label}
                    onChange={(e) => onChange("check", e, index)}
                    className="my-1"
                    checked={state[index].status}
                  />
                </Col>
              ))
            : null} */}
          <Col xs="12" sm="12" md="12" lg="12" xl="12">
            <FormGroup>
              <Label>Choosee Category</Label>
              <AsyncSelect
                isMulti
                onFocus={(e) => {
                  if (e.target.autocomplete) {
                    e.target.autocomplete = "nope";
                  }
                }}
                defaultOptions
                loadOptions={(e) => options(e)}
                onChange={(e) => {
                  onChange(e);
                }}
                defaultValue={state}
                value={state ? state : null}
                components={{ DropdownIndicator: null }}
                placeholder={"Select a category"}
                maxMenuHeight={150}
              />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter style={{ backgroundColor: "#FFFFFF" }}>
        <Button
          color="danger"
          outline
          className="mr-1"
          onClick={() => cancel()}
        >
          Discard
        </Button>
        <Button color="primary" onClick={() => submit()}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export const ModalBannedClaim = ({ modal, cancel }) => {
  return (
    <Modal
      isOpen={modal}
      className="modal-dialog-centered modal-md"
      toggle={() => cancel()}
    >
      <ModalHeader tag={"div"} toggle={() => cancel()}>
        <span className="font-weight-bold">Banned Claim</span>
      </ModalHeader>
      <ModalBody>
        <div className="text-center mb-2 text-dark">
          <div>
            <span>You are banned from submitting claim therefor you</span>
          </div>
          <div>
            <span>can not submit a claim at this moment. Please</span>
          </div>
          <div>
            <span>contact admin to unbanned.</span>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <Button
            color="dark"
            outline
            onClick={() => {
              cancel();
            }}
          >
            Close
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};
