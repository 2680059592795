import Axios from "axios";
import classnames from "classnames";
import React, { useState } from "react";
import "react-feather";
import * as Icon from "react-feather";
import PerfectScrollbar from "react-perfect-scrollbar";
import { AsyncStorage } from "reactjs-async-localstorage";
import {
  Badge,
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from "reactstrap";
import { BASE_URL, SERVICEIMAGE } from "../../../api/config/Config";
import { logout, resetFilter } from "../../../api/controller/AuthController";
import {
  Achievement,
  AchievementW,
  BellNotif,
  CustRecord,
  CustRecordW,
  DefaultProfile,
  InstallationIconBl,
  LeaveBlue,
  Logout,
  LogoutW,
  MGroup,
  MGroupW,
  MaintenanceIconBl,
  NotifCertificate,
  NotifClaims,
  NotifTarget,
  PipeDetail,
  LoanApproved,
  LoanRejected,
  MDistributor,
} from "../../../assets/img/svg";
import Autocomplete from "../../../components/@vuexy/autoComplete/AutoCompleteComponent";
import navigationConfig from "../../../configs/navigationConfig";
import { history } from "../../../history";
import findNotifDate from "../../../utility/NotificationTime";
import { ModalMovePage } from "../../../utility/movePage";
import ImageUser from "../../../utility/ImageUser";
import { getData } from "../../../api/controller/Controller";
import { ModalBannedClaim } from "../../../views/pages/Claims/component/Modal";

const UserDropdown = (props) => {
  const handleLogout = async () => {
    let log = await logout();

    if (log) {
      window.location.href = "/";
    }
  };

  const checkRole = (role) => {
    if (
      role === "superadmin" ||
      role === "admin-finance" ||
      role === "admin-engineer" ||
      role === "admin-purchase" ||
      role === "admin-sales" ||
      role === "lead-engineer" ||
      role === "engineer" ||
      role === "courier"
    ) {
      return true;
    }
    return false;
  };

  const [hover, setHover] = useState(false);
  const [hoverMenu, setHoverMenu] = useState();
  const _hover = (id, bool) => {
    setHover(bool);
    setHoverMenu(id);
  };

  let [modalMove, setModalMove] = useState(false);
  let [moveAction, setMoveAction] = useState(false);
  let [path, setPath] = useState("");
  // let [dataHistory, setDataHistory] = useState({});
  let [modify, setModify] = useState("");

  const cancel = () => {
    setModalMove(!modalMove);
    // setMoveAction();
  };

  const move = (action, value) => {
    action(value);
    setMoveAction();
  };

  const getMove = async () => {
    let data = await AsyncStorage.getItem("MOVE");
    let move = JSON.parse(data);
    setModify(move);
  };

  if (props.get) {
    getMove();
  }

  return (
    <DropdownMenu right>
      <ModalMovePage
        modal={modalMove}
        setModal={setModalMove}
        action={moveAction}
        cancel={cancel}
        move={move}
        // toggleMove={toggle}
        path={path}
        // data={dataHistory}
        // tab={tab}
      />
      <DropdownItem
        id="profile"
        tag="a"
        // href="#"
        onClick={(e) => {
          if (modify) {
            setModalMove(!modalMove);
            setMoveAction("history");
            setPath(`/profile`);
          } else {
            history.push(`/profile`);
          }
          _hover("", false);
          resetFilter();
        }}
        onMouseEnter={() => _hover("profile", true)}
        onMouseLeave={() => _hover("", false)}
      >
        {hover && hoverMenu === "profile" ? (
          <img
            className="mr-50"
            src={MGroupW}
            alt="Profile"
            width={14}
            height={14}
          />
        ) : (
          <img
            className="mr-50"
            src={MGroup}
            alt="Profile"
            width={14}
            height={14}
          />
        )}
        <span className="align-middle">Profile</span>
      </DropdownItem>
      {props.roleUser === "sales" || props.roleUser === "lead-sales" ? (
        <DropdownItem
          id="achievement"
          tag="a"
          // href="#"
          onClick={() => {
            if (modify) {
              setModalMove(!modalMove);
              setMoveAction("history");
              setPath("/achievement");
            } else {
              history.push("/achievement");
            }
            _hover("", false);
            resetFilter();
          }}
          onMouseEnter={() => _hover("achievement", true)}
          onMouseLeave={() => _hover("", false)}
          // onClick={(e) => handleNavigation(e, "/email/inbox")}
        >
          {hover && hoverMenu === "achievement" ? (
            <img
              className="mr-50"
              src={AchievementW}
              alt="Achievement"
              width={14}
              height={14}
            />
          ) : (
            <img
              className="mr-50"
              src={Achievement}
              alt="Achievement"
              width={14}
              height={14}
            />
          )}
          <span className="align-middle">Achievement</span>
        </DropdownItem>
      ) : null}
      {!checkRole(props.roleUser) ? (
        <DropdownItem
          id="customer"
          tag="a"
          // href="#"
          onClick={(e) => {
            if (modify) {
              setModalMove(!modalMove);
              setMoveAction("history");
              setPath(`/customer`);
            } else {
              history.push(`/customer`);
            }
            _hover("", false);
            resetFilter();
          }}
          onMouseEnter={() => _hover("customer", true)}
          onMouseLeave={() => _hover("", false)}
        >
          {hover && hoverMenu === "customer" ? (
            <img
              className="mr-50"
              src={CustRecordW}
              alt="Customer Record"
              width={14}
              height={14}
            />
          ) : (
            <img
              className="mr-50"
              src={CustRecord}
              alt="Customer Record"
              width={14}
              height={14}
            />
          )}
          <span className="align-middle">Cust Record</span>
        </DropdownItem>
      ) : null}

      <DropdownItem divider />
      <DropdownItem
        id="logout"
        tag="a"
        href="/pages/login"
        onClick={(e) => {
          e.preventDefault();
          if (modify) {
            setModalMove(!modalMove);
            setMoveAction("logout");
          } else {
            handleLogout();
          }
        }}
        onMouseEnter={() => _hover("logout", true)}
        onMouseLeave={() => _hover("", false)}
      >
        {hover && hoverMenu === "logout" ? (
          <img
            className="mr-50"
            src={LogoutW}
            alt="Logout"
            width={14}
            height={14}
          />
        ) : (
          <img
            className="mr-50"
            src={Logout}
            alt="Logout"
            width={14}
            height={14}
          />
        )}
        <span className="align-middle">Log Out</span>
      </DropdownItem>
    </DropdownMenu>
  );
};

class NavbarUser extends React.PureComponent {
  _handleLogout = async () => {
    let log = await logout();
    if (log) {
      return;
    }
  };
  state = {
    navbarSearch: false,
    langDropdown: false,
    shoppingCart: [],
    suggestions: [],
    notification: [],
    token: null,
    count_notif: 0,
    id: [],
    get: false,
    modify: false,
    modalMove: false,
    moveAction: "",
    path: "",
    dataHistory: "",
    crashImage: false,
    userBanned: false,
    modalBanned: false,
  };

  UserInfo = (info) => {
    this.setState({
      first_name: info.first_name,
      last_name: info.last_name,
      image: info.image,
      id: info.id,
    });
  };
  UserInfoRole = (info) => {
    this.setState({
      roleUser: info,
    });
  };

  componentDidMount() {
    this.fetchData();
    this.checkBannedClaim();

    setInterval(() => {
      this.setState({
        curTime: new Date().toLocaleString(),
      });
    }, 10000);
  }

  fetchData = () => {
    AsyncStorage.getItem("USER_INFO").then((res) =>
      this.UserInfo(JSON.parse(res))
    );
    AsyncStorage.getItem("USER_ROLE").then((res) => this.UserInfoRole(res));
    this.setState({ suggestions: navigationConfig });
    AsyncStorage.getItem("Auth_ADM_TKN").then((res) => {
      this.setState({ token: res });
      Axios.get(`${BASE_URL}/panel/notification/list?row=1000&page=1`, {
        headers: {
          Authorization: "Bearer " + res,
          Accept: "application/json",
        },
      }).then(({ data }) => {
        if (data?.data?.notification) {
          this.setState({ notification: data?.data?.notification?.data });
          this.setState({ count_notif: data?.data?.count });
        }
      });
    });
  };

  checkBannedClaim = async () => {
    let data = await getData(null, "claim_banned/detail");
    if (data.status === 200) {
      if (data.data.banned) {
        if (data.data.banned.status) {
          this.setState({ userBanned: true });
          // setModalBanned(true);
        }
      }
    }
  };

  getModify = async () => {
    let modify = await AsyncStorage.getItem("MOVE");
    let data = JSON.parse(modify);
    this.setState({ ...this.state, modify: data });
  };

  handleNavbarSearch = async () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch,
    });
    await AsyncStorage.setItem("navbar_search", true);
  };

  removeItem = (id) => {
    let cart = this.state.shoppingCart;

    let updatedCart = cart.filter((i) => i.id !== id);

    this.setState({
      shoppingCart: updatedCart,
    });
  };

  GotoParams = (item, idnotif) => {
    const dropdown = document.getElementById("dropDownMenu");
    dropdown.style.display = "none";

    let data = { id: idnotif };
    Axios.post(`${BASE_URL}/panel/notification/read`, data, {
      headers: {
        Authorization: "Bearer " + this.state.token,
        Accept: "application/json",
      },
    })
      .then((res) => {
        if (res.data.status === 200) {
          if (this.state.modify) {
            this.switchRoleOnModifyItem(item);
          } else {
            this.switchRole(item);
          }
          this.fetchData();
        } else {
          return res.data.message;
        }
        return res.data;
      })
      .catch((err) => {
        // return err;
        this.switchRole(item);
      });
  };

  // switchRole
  switchRole = (item) => {
    switch (item.status) {
      case "claim":
        if (!this.state.userBanned) {
          if (item.data_claim) {
            if (item.data_claim.user.id === this.state.id) {
              history.push("/detail-claims", {
                item: item.data_claim,
                userRole: this.state.roleUser,
                prevPath: "/list-claims",
              });
              window.location.reload();
            } else {
              history.push("/detail-claims", {
                item: item.data_claim,
                userRole: this.state.roleUser,
                prevPath: "/approval-claims",
              });
              window.location.reload();
            }
          } else {
            history.push("/detail-claims", {
              item: item.data_claim,
              userRole: this.state.roleUser,
              prevPath: `/dashboard`,
            });
          }
        } else {
          this.setState({ modalBanned: true });
        }
        break;
      case "certificate":
        history.push("/certificate", item.id_params);
        break;
      case "pipeline":
        if (
          item.pipeline.status === "win" ||
          item.pipeline.status === "revisi"
        ) {
          history.push(`/detail-document/${item.pipeline.id}`, {
            item: item.pipeline,
            tab: "1",
          });
        } else if (item.pipeline.status === "progress_win") {
          if (
            this.state.roleUser === "superadmin" ||
            this.state.roleUser === "admin-sales"
          ) {
            history.push(`/detail-document/${item.pipeline.id}`, {
              item: item.pipeline,
              tab: "2",
            });
          } else {
            history.push(`/detail-document/${item.pipeline.id}`, {
              item: item.pipeline,
              tab: "1",
            });
          }
        } else {
          history.push("/detail-pipeline", {
            item: item,
            tab: "1",
          });
        }
        break;
      case "installations":
        history.push(`/service-detail/${item.id_pipeline}`, {
          type: "Installation",
          id_service: item.id_foreign,
          linkTab: 1,
        });
        break;
      case "maintenance":
        history.push(`/service-detail/${item.id_pipeline}`, {
          type: "Maintenance",
          id_service: item.id_foreign,
          linkTab: 1,
        });
        break;
      case "target":
        history.push(`/profile`);
        break;
      case "pic":
        if (this.state.roleUser === "superadmin") {
          history.push(`/customer-all`);
        } else {
          history.push("/customer");
          // history.push("/detail-customer", {
          //   item: item?.customer?.id,
          //   id_sales: this.UserInfo?.id,
          //   id_user: null,
          // });
        }
        break;
      case "cuti":
        history.push(`/cuti-detail/${item?.id_foreign}`);
        break;
      case "distributor":
        if (item.sub_status === "waiting" || item.sub_status === "approved") {
          if (
            this.state.roleUser !== "lead-sales" &&
            this.state.roleUser !== "sales"
          ) {
            history.push(`/distributor`);
          } else {
            history.push(`/distributor`);
          }
        }
        break;
      case "backup-unit":
        history.push(`/loaned-unit-detail/${item.id_foreign}`);
        break;
      case "customer":
        if (this.state.roleUser === "superadmin") {
          history.push(`/customer-sales/${item.id_user}`);
        } else {
          history.push(`/customer`);
        }
        break;
      default:
        return true;
    }
  };

  // switch role untuk kondisi ketika edit so item
  switchRoleOnModifyItem = (item) => {
    switch (item.status) {
      case "claim":
        if (this.state.userBanned) {
          this.setState({ modalBanned: true });
        } else {
          if (item.data_claim) {
            if (item.data_claim.user.id === this.state.id) {
              this.setState({
                ...this.state,
                moveAction: "history",
                path: "/detail-claims",
                dataHistory: {
                  item: item.data_claim,
                  userRole: this.state.roleUser,
                  prevPath: "/list-claims",
                },
              });
            } else {
              this.setState({
                ...this.state,
                moveAction: "history",
                path: "/detail-claims",
                dataHistory: {
                  item: item.data_claim,
                  userRole: this.state.roleUser,
                  prevPath: "/approval-claims",
                },
              });
            }
          } else {
            this.setState({
              ...this.state,
              moveAction: "history",
              path: "/detail-claims",
              dataHistory: {
                item: item.data_claim,
                userRole: this.state.roleUser,
                prevPath: `/dashboard`,
              },
            });
          }
        }
        break;
      case "certificate":
        this.setState({
          ...this.state,
          moveAction: "history",
          path: "/certificate",
          dataHistory: item.id_params,
        });
        break;
      case "pipeline":
        if (
          item.pipeline.status === "win" ||
          item.pipeline.status === "revisi"
        ) {
          this.setState({
            ...this.state,
            moveAction: "history",
            path: `/detail-document/${item.pipeline.id}`,
            dataHistory: {
              item: item.pipeline,
              tab: "1",
            },
          });
        } else if (item.pipeline.status === "progress_win") {
          if (
            this.state.roleUser === "superadmin" ||
            this.state.roleUser === "admin-sales"
          ) {
            this.setState({
              ...this.state,
              moveAction: "history",
              path: `/detail-document/${item.pipeline.id}`,
              dataHistory: {
                item: item.pipeline,
                tab: "2",
              },
            });
          } else {
            this.setState({
              ...this.state,
              moveAction: "history",
              path: `/detail-document/${item.pipeline.id}`,
              dataHistory: {
                item: item.pipeline,
                tab: "1",
              },
            });
          }
        } else {
          this.setState({
            ...this.state,
            moveAction: "history",
            path: `/detail-pipeline`,
            dataHistory: {
              item: item,
              tab: "1",
            },
          });
        }
        break;
      case "installations":
        this.setState({
          ...this.state,
          moveAction: "history",
          path: `/service-detail/${item.id_pipeline}`,
          dataHistory: {
            type: "Installation",
            item: item.id_foreign,
            linkTab: 1,
          },
        });
        break;
      case "maintenance":
        this.setState({
          ...this.state,
          moveAction: "history",
          path: `/service-detail/${item.id_pipeline}`,
          dataHistory: {
            type: "Maintenance",
            item: item.id_foreign,
            linkTab: 1,
          },
        });
        break;
      case "target":
        this.setState({
          ...this.state,
          moveAction: "history",
          path: `/profile`,
        });
        break;
      case "pic":
        this.setState({
          ...this.state,
          moveAction: "history",
          path:
            this.state.roleUser === "superadmin"
              ? `/customer-all`
              : "/customer",
        });
        break;
      case "cuti":
        this.setState({
          ...this.state,
          moveAction: "history",
          path: `/cuti-detail/${item?.id}`,
        });
        break;
      case "distributor":
        this.setState({
          ...this.state,
          moveAction: "history",
          path:
            this.state.roleUser !== "lead-sales" ||
            this.state.roleUser !== "lead-sales"
              ? `/distributor`
              : "/distributor",
        });
        break;
      default:
        return true;
    }
  };

  handleLangDropdown = () =>
    this.setState({ langDropdown: !this.state.langDropdown });

  ReadAll = async () => {
    const notif = this.state.notification;
    const ids = [];
    await notif.filter((item, value) => {
      if (item.is_read === false) {
        return ids.push(item.id);
      } else {
        return false;
      }
    });
    await this.setState({ ...this.state, id: ids });
    await this._handleReadAll();
  };

  _handleReadAll = () => {
    const data = { id: this.state.id };
    Axios.post(`${BASE_URL}/panel/notification/read_all`, data, {
      headers: {
        Authorization: "Bearer " + this.state.token,
        Accept: "application/json",
      },
    })
      .then((res) => {
        if (res.data.status === 200) {
          return this.fetchData();
        } else {
          console.log("error", res.data.message);
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  showDropDown = () => {
    document.getElementById("dropDownMenu").removeAttribute("style");
  };

  getModify = async () => {
    let modify = await AsyncStorage.getItem("MOVE");
    let data = JSON.parse(modify);
    this.setState({ ...this.state, modify: data });
  };

  cancel = () => {
    this.setState({ ...this.state, modalMove: false });
  };

  cancelBanned = () => {
    this.setState({ ...this.state, modalBanned: false });
  };

  render() {
    // console.log("first", this.state);
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        <ModalMovePage
          modal={this.state.modalMove}
          setModal={(e) => this.setState({ ...this.state, modalMove: e })}
          action={this.state.moveAction}
          cancel={this.cancel}
          path={this.state.path}
          data={this.state.dataHistory}
        />
        <ModalBannedClaim
          modal={this.state.modalBanned}
          cancel={this.cancelBanned}
        />
        <NavItem className="nav-search" onClick={this.handleNavbarSearch}>
          <NavLink className="nav-link-search">
            <Icon.Search size={26} data-tour="search" />
          </NavLink>
          <div
            className={classnames("search-input", {
              open: this.state.navbarSearch,
              "d-none": this.state.navbarSearch === false,
            })}
          >
            <div className="search-input-icon">
              <Icon.Search size={17} className="primary" />
            </div>
            <Autocomplete
              className="form-control"
              suggestions={this.state.suggestions}
              filterKey="title"
              placeholder="Menu Fi-Nis..."
              autoFocus={true}
              clearInput={this.state.navbarSearch}
              externalClick={async (e) => {
                this.setState({ navbarSearch: false });
                await AsyncStorage.removeItem("navbar_search");
              }}
              onKeyDown={async (e) => {
                if (e.keyCode === 27 || e.keyCode === 13) {
                  this.setState({
                    navbarSearch: false,
                  });
                  this.props.handleAppOverlay("");
                  await AsyncStorage.removeItem("navbar_search");
                }
              }}
              customRender={(
                item,
                i,
                filteredData,
                activeSuggestion,
                onSuggestionItemClick,
                onSuggestionItemHover
              ) => {
                return (
                  <div key={i}>
                    {item.children ? (
                      item.children.map((item, index) => (
                        <li
                          className={classnames("suggestion-item", {
                            active:
                              filteredData.indexOf(item) === activeSuggestion,
                          })}
                          key={index}
                          onClick={(e) =>
                            onSuggestionItemClick(
                              item?.navLink ? item?.navLink : "",
                              e
                            )
                          }
                          style={{ background: "#fff" }}
                          onMouseEnter={() =>
                            onSuggestionItemHover(filteredData.indexOf(item))
                          }
                        >
                          <div
                            className={
                              "d-flex justify-content-between align-items-center"
                            }
                            style={{ marginTop: 5 }}
                          >
                            <p>
                              {item?.icon}
                              {" " + item?.title}
                            </p>
                          </div>
                        </li>
                      ))
                    ) : (
                      <li
                        className={classnames("suggestion-item", {
                          active:
                            filteredData.indexOf(item) === activeSuggestion,
                        })}
                        key={i}
                        onClick={(e) =>
                          onSuggestionItemClick(
                            item?.navLink ? item?.navLink : "",
                            e
                          )
                        }
                        style={{ background: "#fff" }}
                        onMouseEnter={() =>
                          onSuggestionItemHover(filteredData.indexOf(item))
                        }
                      >
                        <div
                          className={
                            "d-flex justify-content-between align-items-center"
                          }
                          style={{ paddingTop: 10 }}
                        >
                          <p>
                            {item?.icon}
                            {" " + item?.title}
                          </p>
                        </div>
                      </li>
                    )}
                  </div>
                );
              }}
              onSuggestionsShown={(userInput) => {
                if (this.state.navbarSearch) {
                  this.props.handleAppOverlay(userInput);
                }
              }}
            />
            <div className="search-input-close">
              <Icon.X
                size={24}
                onClick={async (e) => {
                  e.stopPropagation();
                  this.setState({
                    navbarSearch: false,
                  });
                  this.props.handleAppOverlay("");
                  await AsyncStorage.removeItem("navbar_search");
                }}
              />
            </div>
          </div>
        </NavItem>
        <UncontrolledDropdown tag="li" className=" nav-item">
          <DropdownToggle
            tag="a"
            className="nav-link nav-link-label"
            onClick={() => {
              this.showDropDown();
              this.setState({ ...this.state, get: true });
              this.getModify();
            }}
          >
            {/* <Icon.Bell size={26} /> */}
            <img src={BellNotif} alt="Bell" width={24} height={24} />
            {this?.state?.count_notif > 0 ? (
              <Badge
                pill
                color="danger"
                style={{
                  position: "absolute",
                  left: 23,
                  top: 15,
                }}
              >
                {this?.state?.count_notif}
              </Badge>
            ) : null}
          </DropdownToggle>
          <DropdownMenu
            tag="ul"
            right
            className="dropdown-menu-media "
            id="dropDownMenu"
          >
            <li className="dropdown-menu-header" style={{ height: 25 }}>
              <div
                className="row justify-content-between"
                style={{ marginTop: -10 }}
              >
                <div className="pl-1">
                  <h5> Notifications</h5>
                </div>
                {this.state.count_notif ? (
                  <div className="pr-1 mb-2">
                    <Badge
                      pill
                      style={{
                        color: "#7268E8",
                        backgroundColor: "#EEEDFC",
                        fontSize: 12,
                      }}
                    >
                      {this.state.count_notif} new
                    </Badge>
                  </div>
                ) : null}
              </div>
            </li>
            <PerfectScrollbar
              className="media-list overflow-hidden position-relative"
              options={{
                wheelPropagation: false,
              }}
            >
              {this.state?.notification.length !== 0 ? (
                this.state?.notification.map((item, index) => {
                  switch (item.status) {
                    case "pipeline":
                      return (
                        <div
                          key={index}
                          className="d-flex px-1 py-1 border-bottom"
                          style={{
                            backgroundColor: item.is_read
                              ? "#FFFFFF"
                              : "#f1f8ff",
                          }}
                          onClick={() => {
                            if (this.state.modify) {
                              this.setState({
                                ...this.state,
                                modalMove: true,
                                moveAction: "notif",
                                theAction: this.GotoParams(item, item.id),
                              });
                            } else {
                              this.GotoParams(item, item.id);
                            }
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "#E5EEF4",
                              borderRadius: "30px",
                              padding: "20px",
                              width: "36px",
                              height: "36px",
                            }}
                          >
                            <div className="h-100 row d-flex justify-content-center align-content-center">
                              {/* <Icon.Bookmark size={18} color={"#005391"} /> */}
                              <img
                                src={PipeDetail}
                                alt="Pipeline"
                                width={18}
                                height={18}
                                style={{ color: "#005391" }}
                              />
                            </div>
                          </div>
                          <div
                            // className="d-flex"
                            style={{
                              display: "grid",
                              gridTemplateColumns: "15rem 1fr 0.5fr",
                            }}
                          >
                            <div className="ml-1 d-block align-items-center">
                              <div>
                                <h6 className="font-weight-bolder">
                                  {item.head_notification}
                                </h6>
                              </div>
                              <div>
                                <h6 className="font-weight-light">
                                  {item.description_notification}
                                </h6>
                              </div>
                            </div>
                            <div className="d-block align-items-center">
                              <Badge
                                pill
                                style={{
                                  color: "#7268E8",
                                  backgroundColor: "#EEEDFC",
                                  fontSize: 12,
                                  marginBottom: "0.5rem",
                                }}
                              >
                                {findNotifDate(item.created_at)}
                              </Badge>
                            </div>
                          </div>
                        </div>
                      );
                    case "certificate":
                      switch (item.sub_status) {
                        case "waiting":
                          return (
                            <div
                              key={index}
                              className="d-flex px-1 py-1 border-bottom"
                              style={{
                                backgroundColor: item.is_read
                                  ? "#FFFFFF"
                                  : "#f1f8ff",
                              }}
                              onClick={() => {
                                if (this.state.modify) {
                                  this.setState({
                                    ...this.state,
                                    modalMove: true,
                                    moveAction: "notif",
                                    theAction: this.GotoParams(item, item.id),
                                  });
                                } else {
                                  this.GotoParams(item, item.id);
                                }
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#E5EEF4",
                                  borderRadius: "30px",
                                  padding: "20px",
                                  width: "36px",
                                  height: "36px",
                                }}
                              >
                                <div className="h-100 row d-flex justify-content-center align-content-center">
                                  {/* <Icon.File size={18} color={"#005391"} /> */}
                                  <img
                                    src={NotifCertificate}
                                    alt="Certification"
                                    width={18}
                                    height={18}
                                    style={{ color: "#005391" }}
                                  />
                                </div>
                              </div>
                              <div
                                // className="d-flex"
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "15rem 1fr 0.5fr",
                                }}
                              >
                                <div className="ml-1 d-block align-items-center">
                                  <div>
                                    <h6 className="font-weight-bolder">
                                      Waiting for certificate approval
                                    </h6>
                                  </div>
                                  <div>
                                    <h6 className="font-weight-light">
                                      {item.value_1} just uploaded his
                                      certificate.
                                    </h6>
                                  </div>
                                </div>
                                <div className="d-block align-items-center">
                                  <Badge
                                    pill
                                    // color="danger" //{item.is_read ? "#FFFFFF" : "#f1f8ff"}
                                    style={{
                                      color: "#7268E8",
                                      backgroundColor: "#EEEDFC",
                                      fontSize: 12,
                                      marginBottom: "0.5rem",
                                    }}
                                  >
                                    {findNotifDate(item.created_at)}
                                  </Badge>
                                </div>
                              </div>
                            </div>
                          );
                        case "approved":
                          return (
                            <div
                              key={index}
                              className="d-flex px-1 py-1 border-bottom"
                              style={{
                                backgroundColor: item.is_read
                                  ? "#FFFFFF"
                                  : "#f1f8ff",
                              }}
                              onClick={() => {
                                if (this.state.modify) {
                                  this.setState({
                                    ...this.state,
                                    modalMove: true,
                                    moveAction: "notif",
                                    theAction: this.GotoParams(item, item.id),
                                  });
                                } else {
                                  this.GotoParams(item, item.id);
                                }
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#E5EEF4",
                                  borderRadius: "30px",
                                  padding: "20px",
                                  width: "36px",
                                  height: "36px",
                                }}
                              >
                                <div className="h-100 row d-flex justify-content-center align-content-center">
                                  <Icon.File size={30} color={"#005391"} />
                                </div>
                              </div>
                              <div
                                // className="d-flex"
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "15rem 1fr 0.5fr",
                                }}
                              >
                                <div className="ml-1 d-block align-items-center">
                                  <div>
                                    <h6 className="font-weight-bolder">
                                      Certificate approval
                                    </h6>
                                  </div>
                                  <div>
                                    <h6 className="font-weight-light">
                                      Certificate {item.value_2} has been
                                      approved.
                                    </h6>
                                  </div>
                                </div>
                                <div className="d-block align-items-center">
                                  <Badge
                                    pill
                                    // color="danger" //{item.is_read ? "#FFFFFF" : "#f1f8ff"}
                                    style={{
                                      color: "#7268E8",
                                      backgroundColor: "#EEEDFC",
                                      fontSize: 12,
                                      marginBottom: "0.5rem",
                                    }}
                                  >
                                    {findNotifDate(item.created_at)}
                                  </Badge>
                                </div>
                              </div>
                            </div>
                          );
                        default:
                          return (
                            <div
                              key={index}
                              className="d-flex px-1 py-1 border-bottom"
                              style={{
                                backgroundColor: item.is_read
                                  ? "#FFFFFF"
                                  : "#f1f8ff",
                              }}
                              onClick={() => {
                                if (this.state.modify) {
                                  this.setState({
                                    ...this.state,
                                    modalMove: true,
                                    moveAction: "notif",
                                    theAction: this.GotoParams(item, item.id),
                                  });
                                } else {
                                  this.GotoParams(item, item.id);
                                }
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#E5EEF4",
                                  borderRadius: "30px",
                                  padding: "20px",
                                  width: "36px",
                                  height: "36px",
                                }}
                              >
                                <div className="h-100 row d-flex justify-content-center align-content-center">
                                  <Icon.File size={30} color={"#005391"} />
                                </div>
                              </div>
                              <div
                                // className="d-flex"
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "15rem 1fr 0.5fr",
                                }}
                              >
                                <div className="ml-1 d-block align-items-center">
                                  <div>
                                    <h6 className="font-weight-bolder">
                                      Certificate approval
                                    </h6>
                                  </div>
                                  <div>
                                    <h6 className="font-weight-light">
                                      Certificate {item.value_2} has been
                                      rejected.
                                    </h6>
                                  </div>
                                </div>
                                <div className="d-block align-items-center">
                                  <Badge
                                    pill
                                    // color="danger" //{item.is_read ? "#FFFFFF" : "#f1f8ff"}
                                    style={{
                                      color: "#7268E8",
                                      backgroundColor: "#EEEDFC",
                                      fontSize: 12,
                                      marginBottom: "0.5rem",
                                    }}
                                  >
                                    {findNotifDate(item.created_at)}
                                  </Badge>
                                </div>
                              </div>
                            </div>
                          );
                      }
                    case "claim":
                      return (
                        <div
                          key={index}
                          className="d-flex px-1 py-1 border-bottom"
                          style={{
                            backgroundColor: item.is_read
                              ? "#FFFFFF"
                              : "#f1f8ff",
                          }}
                          onClick={() => {
                            if (this.state.modify) {
                              this.setState({
                                ...this.state,
                                modalMove: true,
                                moveAction: "notif",
                                theAction: this.GotoParams(item, item.id),
                              });
                            } else {
                              this.GotoParams(item, item.id);
                            }
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "#E5EEF4",
                              borderRadius: "30px",
                              padding: "20px",
                              width: "36px",
                              height: "36px",
                            }}
                          >
                            <div className="h-100 row d-flex justify-content-center align-content-center">
                              {/* <Icon.Book size={18} color={"#005391"} /> */}
                              <img
                                src={NotifClaims}
                                alt="Claims"
                                width={18}
                                height={18}
                              />
                            </div>
                          </div>
                          <div
                            // className="d-flex"
                            style={{
                              display: "grid",
                              gridTemplateColumns: "15rem 1fr 0.5fr",
                            }}
                          >
                            <div className="ml-1 d-block align-items-center">
                              <div>
                                <h6 className="font-weight-bolder text-capitalize">
                                  {item?.head_notification}
                                </h6>
                              </div>
                              <div>
                                <h6 className="font-weight-light">
                                  {item?.description_notification}
                                </h6>
                              </div>
                            </div>
                            <div className="d-block align-items-center">
                              <Badge
                                pill
                                // color="danger" //{item.is_read ? "#FFFFFF" : "#f1f8ff"}
                                style={{
                                  color: "#7268E8",
                                  backgroundColor: "#EEEDFC",
                                  fontSize: 12,
                                  marginBottom: "0.5rem",
                                }}
                                className="d-block"
                              >
                                {findNotifDate(item.created_at)}
                              </Badge>
                            </div>
                          </div>
                        </div>
                      );
                    case "installations":
                      return (
                        <div
                          key={index}
                          className="d-flex px-1 py-1 border-bottom"
                          style={{
                            backgroundColor: item.is_read
                              ? "#FFFFFF"
                              : "#f1f8ff",
                          }}
                          onClick={() => {
                            if (this.state.modify) {
                              this.setState({
                                ...this.state,
                                modalMove: true,
                                moveAction: "notif",
                                theAction: this.GotoParams(item, item.id),
                              });
                            } else {
                              this.GotoParams(item, item.id);
                            }
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "#E5EEF4",
                              borderRadius: "30px",
                              padding: "20px",
                              width: "36px",
                              height: "36px",
                            }}
                          >
                            <div className="h-100 row d-flex justify-content-center align-content-center">
                              <img
                                src={InstallationIconBl}
                                alt="Installation"
                                width={18}
                                height={18}
                                // color={"#005391"}
                              />
                            </div>
                          </div>
                          <div
                            // className="d-flex"
                            style={{
                              display: "grid",
                              gridTemplateColumns: "15rem 1fr 0.5fr",
                            }}
                          >
                            <div className="ml-1 d-block align-items-center">
                              <div>
                                <h6 className="font-weight-bolder text-capitalize">
                                  {item?.header_notification}
                                </h6>
                              </div>
                              <div>
                                <h6 className="font-weight-light">
                                  {item?.description_notification}
                                </h6>
                              </div>
                            </div>
                            <div className="d-block align-items-center">
                              <Badge
                                pill
                                // color="danger" //{item.is_read ? "#FFFFFF" : "#f1f8ff"}
                                style={{
                                  color: "#7268E8",
                                  backgroundColor: "#EEEDFC",
                                  fontSize: 12,
                                  marginBottom: "0.5rem",
                                }}
                              >
                                {findNotifDate(item.created_at)}
                              </Badge>
                            </div>
                          </div>
                        </div>
                      );
                    case "maintenance":
                      return (
                        <div
                          key={index}
                          className="d-flex px-1 py-1 border-bottom"
                          style={{
                            backgroundColor: item.is_read
                              ? "#FFFFFF"
                              : "#f1f8ff",
                          }}
                          onClick={() => {
                            if (this.state.modify) {
                              this.setState({
                                ...this.state,
                                modalMove: true,
                                moveAction: "notif",
                                theAction: this.GotoParams(item, item.id),
                              });
                            } else {
                              this.GotoParams(item, item.id);
                            }
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "#E5EEF4",
                              borderRadius: "30px",
                              padding: "20px",
                              width: "36px",
                              height: "36px",
                            }}
                          >
                            <div className="h-100 row d-flex justify-content-center align-content-center">
                              <img
                                src={MaintenanceIconBl}
                                alt="Maintenance"
                                width={18}
                                height={18}
                                // color={"#005391"}
                              />
                            </div>
                          </div>
                          <div
                            // className="d-flex"
                            style={{
                              display: "grid",
                              gridTemplateColumns: "15rem 1fr 0.5fr",
                            }}
                          >
                            <div className="ml-1 d-block align-items-center">
                              <div>
                                <h6 className="font-weight-bolder text-capitalize">
                                  {item?.header_notification}
                                </h6>
                              </div>
                              <div>
                                <h6 className="font-weight-light">
                                  {item?.description_notification}
                                </h6>
                              </div>
                            </div>
                            <div className="d-block align-items-center">
                              <Badge
                                pill
                                // color="danger" //{item.is_read ? "#FFFFFF" : "#f1f8ff"}
                                style={{
                                  color: "#7268E8",
                                  backgroundColor: "#EEEDFC",
                                  fontSize: 12,
                                  marginBottom: "0.5rem",
                                }}
                              >
                                {findNotifDate(item.created_at)}
                              </Badge>
                            </div>
                          </div>
                        </div>
                      );
                    case "target":
                      return (
                        <div
                          key={index}
                          className="d-flex px-1 py-1 border-bottom"
                          style={{
                            backgroundColor: item.is_read
                              ? "#FFFFFF"
                              : "#f1f8ff",
                          }}
                          onClick={() => {
                            if (this.state.modify) {
                              this.setState({
                                ...this.state,
                                modalMove: true,
                                moveAction: "notif",
                                theAction: this.GotoParams(item, item.id),
                              });
                            } else {
                              this.GotoParams(item, item.id);
                            }
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "#E5EEF4",
                              borderRadius: "30px",
                              padding: "20px",
                              width: "36px",
                              height: "36px",
                            }}
                          >
                            <div className="h-100 row d-flex justify-content-center align-content-center">
                              <img
                                src={NotifTarget}
                                alt="backup-unit"
                                width={18}
                                height={18}
                                // color={"#005391"}
                              />
                            </div>
                          </div>
                          <div
                            // className="d-flex"
                            style={{
                              display: "grid",
                              gridTemplateColumns: "15rem 1fr 0.5fr",
                            }}
                          >
                            <div className="ml-1 d-block align-items-center">
                              <div>
                                <h6 className="font-weight-bolder text-capitalize">
                                  {item?.header_notification}
                                </h6>
                              </div>
                              <div>
                                <h6 className="font-weight-light">
                                  {item?.description_notification}
                                </h6>
                              </div>
                            </div>
                            <div className="d-block align-items-center">
                              <Badge
                                pill
                                // color="danger" //{item.is_read ? "#FFFFFF" : "#f1f8ff"}
                                style={{
                                  color: "#7268E8",
                                  backgroundColor: "#EEEDFC",
                                  fontSize: 12,
                                  marginBottom: "0.5rem",
                                }}
                              >
                                {findNotifDate(item.created_at)}
                              </Badge>
                            </div>
                          </div>
                        </div>
                      );
                    case "distributor":
                      return (
                        <div
                          key={index}
                          className="d-flex px-1 py-1 border-bottom"
                          style={{
                            backgroundColor: item.is_read
                              ? "#FFFFFF"
                              : "#f1f8ff",
                          }}
                          onClick={() => {
                            if (this.state.modify) {
                              this.setState({
                                ...this.state,
                                modalMove: true,
                                moveAction: "notif",
                                theAction: this.GotoParams(item, item.id),
                              });
                            } else {
                              this.GotoParams(item, item.id);
                            }
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "#E5EEF4",
                              borderRadius: "30px",
                              padding: "20px",
                              width: "36px",
                              height: "36px",
                            }}
                          >
                            <div className="h-100 row d-flex justify-content-center align-content-center">
                              {/* <Icon.Book size={18} color={"#005391"} /> */}
                              <img
                                src={MDistributor}
                                alt="distributor"
                                width={18}
                                height={18}
                              />
                            </div>
                          </div>
                          <div
                            // className="d-flex"
                            style={{
                              display: "grid",
                              gridTemplateColumns: "15rem 1fr 0.5fr",
                            }}
                          >
                            <div className="ml-1 d-block align-items-center">
                              <div>
                                <h6 className="font-weight-bolder text-capitalize">
                                  {item?.header_notification}
                                </h6>
                              </div>
                              <div>
                                <h6 className="font-weight-light">
                                  {item?.description_notification}
                                </h6>
                              </div>
                            </div>
                            <div className="d-block align-items-center">
                              <Badge
                                pill
                                // color="danger" //{item.is_read ? "#FFFFFF" : "#f1f8ff"}
                                style={{
                                  color: "#7268E8",
                                  backgroundColor: "#EEEDFC",
                                  fontSize: 12,
                                  marginBottom: "0.5rem",
                                }}
                                className="d-block"
                              >
                                {findNotifDate(item.created_at)}
                              </Badge>
                            </div>
                          </div>
                        </div>
                      );
                    case "pic":
                      switch (item.sub_status) {
                        case "waiting":
                          return (
                            <div
                              key={index}
                              className="d-flex px-1 py-1 border-bottom"
                              style={{
                                backgroundColor: item.is_read
                                  ? "#FFFFFF"
                                  : "#f1f8ff",
                              }}
                              onClick={() => {
                                this.GotoParams(item, item.id);
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#E5EEF4",
                                  borderRadius: "30px",
                                  padding: "20px",
                                  width: "36px",
                                  height: "36px",
                                }}
                              >
                                <div className="h-100 row d-flex justify-content-center align-content-center">
                                  <img
                                    src={SERVICEIMAGE + item?.user_image}
                                    alt="pic"
                                    width={40}
                                    height={40}
                                    onError={(e) =>
                                      (e.currentTarget.src = DefaultProfile)
                                    }
                                  />
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "15rem 1fr 0.5fr",
                                }}
                              >
                                <div className="ml-1 d-block align-items-center">
                                  <div>
                                    <h6 className="font-weight-bolder text-capitalize">
                                      {item?.value_2}
                                    </h6>
                                  </div>
                                  <div>
                                    <h6 className="font-weight-light">
                                      {item?.description}
                                    </h6>
                                  </div>
                                </div>
                                <div className="d-block align-items-center">
                                  <Badge
                                    pill
                                    // color="danger" //{item.is_read ? "#FFFFFF" : "#f1f8ff"}
                                    style={{
                                      color: "#7268E8",
                                      backgroundColor: "#EEEDFC",
                                      fontSize: 12,
                                      marginBottom: "0.5rem",
                                    }}
                                    className="d-block"
                                  >
                                    {findNotifDate(item.created_at)}
                                  </Badge>
                                </div>
                              </div>
                            </div>
                          );
                        case "approved":
                          return (
                            <div
                              key={index}
                              className="d-flex px-1 py-1 border-bottom"
                              style={{
                                backgroundColor: item.is_read
                                  ? "#FFFFFF"
                                  : "#f1f8ff",
                              }}
                              onClick={() => {
                                this.GotoParams(item, item.id);
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#E5EEF4",
                                  borderRadius: "30px",
                                  padding: "20px",
                                  width: "36px",
                                  height: "36px",
                                }}
                              >
                                <div className="h-100 row d-flex justify-content-center align-content-center">
                                  <img
                                    src={SERVICEIMAGE + item?.user_image}
                                    alt="pic"
                                    width={40}
                                    height={40}
                                    onError={(e) =>
                                      (e.currentTarget.src = DefaultProfile)
                                    }
                                  />
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "15rem 1fr 0.5fr",
                                }}
                              >
                                <div className="ml-1 d-block align-items-center">
                                  <div>
                                    <h6 className="font-weight-bold text-capitalize">
                                      {item?.header_notification}
                                    </h6>
                                  </div>
                                  <div>
                                    <h6 className="font-weight-light">
                                      {item?.description_notification}
                                    </h6>
                                  </div>
                                </div>
                                <div className="d-block align-items-center">
                                  <Badge
                                    pill
                                    // color="danger" //{item.is_read ? "#FFFFFF" : "#f1f8ff"}
                                    style={{
                                      color: "#7268E8",
                                      backgroundColor: "#EEEDFC",
                                      fontSize: 12,
                                      marginBottom: "0.5rem",
                                    }}
                                    className="d-block"
                                  >
                                    {findNotifDate(item.created_at)}
                                  </Badge>
                                </div>
                              </div>
                            </div>
                          );
                        case "rejected":
                          return (
                            <div
                              key={index}
                              className="d-flex px-1 py-1 border-bottom"
                              style={{
                                backgroundColor: item.is_read
                                  ? "#FFFFFF"
                                  : "#f1f8ff",
                              }}
                              onClick={() => {
                                this.GotoParams(item, item.id);
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#E5EEF4",
                                  borderRadius: "30px",
                                  padding: "20px",
                                  width: "36px",
                                  height: "36px",
                                }}
                              >
                                <div className="h-100 row d-flex justify-content-center align-content-center">
                                  <img
                                    src={SERVICEIMAGE + item?.user_image}
                                    alt="pic"
                                    width={40}
                                    height={40}
                                    onError={(e) =>
                                      (e.currentTarget.src = DefaultProfile)
                                    }
                                  />
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "15rem 1fr 0.5fr",
                                }}
                              >
                                <div className="ml-1 d-block align-items-center">
                                  <div>
                                    <h6 className="font-weight-bold text-capitalize">
                                      {item?.header_notification}
                                    </h6>
                                  </div>
                                  <div>
                                    <h6 className="font-weight-light">
                                      {item?.description_notification}
                                    </h6>
                                  </div>
                                </div>
                                <div className="d-block align-items-center">
                                  <Badge
                                    pill
                                    // color="danger" //{item.is_read ? "#FFFFFF" : "#f1f8ff"}
                                    style={{
                                      color: "#7268E8",
                                      backgroundColor: "#EEEDFC",
                                      fontSize: 12,
                                      marginBottom: "0.5rem",
                                    }}
                                    className="d-block"
                                  >
                                    {findNotifDate(item.created_at)}
                                  </Badge>
                                </div>
                              </div>
                            </div>
                          );
                        default:
                          return (
                            <div
                              key={index}
                              className="d-flex px-1 py-1 border-bottom"
                              style={{
                                backgroundColor: item.is_read
                                  ? "#FFFFFF"
                                  : "#f1f8ff",
                              }}
                              onClick={() => {
                                this.GotoParams(item, item.id);
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#E5EEF4",
                                  borderRadius: "30px",
                                  padding: "20px",
                                  width: "36px",
                                  height: "36px",
                                }}
                              >
                                <div className="h-100 row d-flex justify-content-center align-content-center">
                                  <img
                                    src={SERVICEIMAGE + item?.user_image}
                                    alt="pic"
                                    width={18}
                                    height={18}
                                    onError={(e) =>
                                      (e.currentTarget.src = DefaultProfile)
                                    }
                                  />
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "15rem 1fr 0.5fr",
                                }}
                              >
                                <div className="ml-1 d-block align-items-center">
                                  <div>
                                    <h6 className="font-weight-bolder text-capitalize">
                                      {item?.value_2}
                                    </h6>
                                  </div>
                                  <div>
                                    <h6 className="font-weight-light">
                                      {item?.description}
                                    </h6>
                                  </div>
                                </div>
                                <div className="d-block align-items-center">
                                  <Badge
                                    pill
                                    // color="danger" //{item.is_read ? "#FFFFFF" : "#f1f8ff"}
                                    style={{
                                      color: "#7268E8",
                                      backgroundColor: "#EEEDFC",
                                      fontSize: 12,
                                      marginBottom: "0.5rem",
                                    }}
                                    className="d-block"
                                  >
                                    {findNotifDate(item.created_at)}
                                  </Badge>
                                </div>
                              </div>
                            </div>
                          );
                      }
                    case "cuti":
                      return (
                        <div
                          key={index}
                          className="d-flex px-1 py-1 border-bottom"
                          style={{
                            backgroundColor: item.is_read
                              ? "#FFFFFF"
                              : "#f1f8ff",
                          }}
                          onClick={() => {
                            if (this.state.modify) {
                              this.setState({
                                ...this.state,
                                modalMove: true,
                                moveAction: "notif",
                                theAction: this.GotoParams(item, item.id),
                              });
                            } else {
                              this.GotoParams(item, item.id);
                            }
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "#E5EEF4",
                              borderRadius: "30px",
                              padding: "20px",
                              width: "36px",
                              height: "36px",
                            }}
                          >
                            <div className="h-100 row d-flex justify-content-center align-content-center">
                              {/* <Icon.Book size={18} color={"#005391"} /> */}
                              <img
                                src={LeaveBlue}
                                alt="Cuti"
                                width={18}
                                height={18}
                              />
                            </div>
                          </div>
                          <div
                            // className="d-flex"
                            style={{
                              display: "grid",
                              gridTemplateColumns: "15rem 1fr 0.5fr",
                            }}
                          >
                            <div className="ml-1 d-block align-items-center">
                              <div>
                                <h6 className="font-weight-bolder text-capitalize">
                                  {item?.head_notification}
                                </h6>
                              </div>
                              <div>
                                <h6 className="font-weight-light">
                                  {item?.description_notification}
                                </h6>
                              </div>
                            </div>
                            <div className="d-block align-items-center">
                              <Badge
                                pill
                                // color="danger" //{item.is_read ? "#FFFFFF" : "#f1f8ff"}
                                style={{
                                  color: "#7268E8",
                                  backgroundColor: "#EEEDFC",
                                  fontSize: 12,
                                  marginBottom: "0.5rem",
                                }}
                                className="d-block"
                              >
                                {findNotifDate(item.created_at)}
                              </Badge>
                            </div>
                          </div>
                        </div>
                      );
                    case "backup-unit":
                      switch (item.sub_status) {
                        case "waiting":
                          return (
                            <div
                              key={index}
                              className="d-flex px-1 py-1 border-bottom"
                              style={{
                                backgroundColor: item.is_read
                                  ? "#FFFFFF"
                                  : "#f1f8ff",
                              }}
                              onClick={() => {
                                this.GotoParams(item, item.id);
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#E5EEF4",
                                  borderRadius: "30px",
                                  padding: "20px",
                                  width: "36px",
                                  height: "36px",
                                }}
                              >
                                <div className="h-100 row d-flex justify-content-center align-content-center">
                                  <img
                                    src={SERVICEIMAGE + item?.user_image}
                                    alt="pic"
                                    width={40}
                                    height={40}
                                    onError={(e) =>
                                      (e.currentTarget.src = DefaultProfile)
                                    }
                                  />
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "15rem 1fr 0.5fr",
                                }}
                              >
                                <div className="ml-1 d-block align-items-center">
                                  <div>
                                    <h6 className="font-weight-bolder text-capitalize">
                                      {item?.head_notification}
                                    </h6>
                                  </div>
                                  <div>
                                    <h6 className="font-weight-light">
                                      {item?.description_notification}
                                    </h6>
                                  </div>
                                </div>
                                <div className="d-block align-items-center">
                                  <Badge
                                    pill
                                    // color="danger" //{item.is_read ? "#FFFFFF" : "#f1f8ff"}
                                    style={{
                                      color: "#7268E8",
                                      backgroundColor: "#EEEDFC",
                                      fontSize: 12,
                                      marginBottom: "0.5rem",
                                    }}
                                    className="d-block"
                                  >
                                    {findNotifDate(item.created_at)}
                                  </Badge>
                                </div>
                              </div>
                            </div>
                          );
                        case "approved":
                          return (
                            <div
                              key={index}
                              className="d-flex px-1 py-1 border-bottom"
                              style={{
                                backgroundColor: item.is_read
                                  ? "#FFFFFF"
                                  : "#f1f8ff",
                              }}
                              onClick={() => {
                                this.GotoParams(item, item.id);
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#E5EEF4",
                                  borderRadius: "30px",
                                  padding: "20px",
                                  width: "36px",
                                  height: "36px",
                                }}
                              >
                                <div className="h-100 row d-flex justify-content-center align-content-center">
                                  <img
                                    src={LoanApproved}
                                    alt="pic"
                                    width={40}
                                    height={40}
                                    onError={(e) =>
                                      (e.currentTarget.src = DefaultProfile)
                                    }
                                  />
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "15rem 1fr 0.5fr",
                                }}
                              >
                                <div className="ml-1 d-block align-items-center">
                                  <div>
                                    <h6 className="font-weight-bolder text-capitalize">
                                      {item?.head_notification}
                                    </h6>
                                  </div>
                                  <div>
                                    <h6 className="font-weight-light">
                                      {item?.description_notification}
                                    </h6>
                                  </div>
                                </div>
                                <div className="d-block align-items-center">
                                  <Badge
                                    pill
                                    style={{
                                      color: "#7268E8",
                                      backgroundColor: "#EEEDFC",
                                      fontSize: 12,
                                      marginBottom: "0.5rem",
                                    }}
                                    className="d-block"
                                  >
                                    {findNotifDate(item.created_at)}
                                  </Badge>
                                </div>
                              </div>
                            </div>
                          );
                        case "finish":
                          return (
                            <div
                              key={index}
                              className="d-flex px-1 py-1 border-bottom"
                              style={{
                                backgroundColor: item.is_read
                                  ? "#FFFFFF"
                                  : "#f1f8ff",
                              }}
                              onClick={() => {
                                this.GotoParams(item, item.id);
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#E5EEF4",
                                  borderRadius: "30px",
                                  padding: "20px",
                                  width: "36px",
                                  height: "36px",
                                }}
                              >
                                <div className="h-100 row d-flex justify-content-center align-content-center">
                                  <img
                                    src={LoanApproved}
                                    alt="pic"
                                    width={40}
                                    height={40}
                                    onError={(e) =>
                                      (e.currentTarget.src = DefaultProfile)
                                    }
                                  />
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "15rem 1fr 0.5fr",
                                }}
                              >
                                <div className="ml-1 d-block align-items-center">
                                  <div>
                                    <h6 className="font-weight-bolder text-capitalize">
                                      {item?.head_notification}
                                    </h6>
                                  </div>
                                  <div>
                                    <h6 className="font-weight-light">
                                      {item?.description_notification}
                                    </h6>
                                  </div>
                                </div>
                                <div className="d-block align-items-center">
                                  <Badge
                                    pill
                                    style={{
                                      color: "#7268E8",
                                      backgroundColor: "#EEEDFC",
                                      fontSize: 12,
                                      marginBottom: "0.5rem",
                                    }}
                                    className="d-block"
                                  >
                                    {findNotifDate(item.created_at)}
                                  </Badge>
                                </div>
                              </div>
                            </div>
                          );
                        case "rejected":
                          return (
                            <div
                              key={index}
                              className="d-flex px-1 py-1 border-bottom"
                              style={{
                                backgroundColor: item.is_read
                                  ? "#FFFFFF"
                                  : "#f1f8ff",
                              }}
                              onClick={() => {
                                this.GotoParams(item, item.id);
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#E5EEF4",
                                  borderRadius: "30px",
                                  padding: "20px",
                                  width: "36px",
                                  height: "36px",
                                }}
                              >
                                <div className="h-100 row d-flex justify-content-center align-content-center">
                                  <img
                                    src={LoanRejected}
                                    alt="pic"
                                    width={40}
                                    height={40}
                                    onError={(e) =>
                                      (e.currentTarget.src = DefaultProfile)
                                    }
                                  />
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "15rem 1fr 0.5fr",
                                }}
                              >
                                <div className="ml-1 d-block align-items-center">
                                  <div>
                                    <h6 className="font-weight-bolder text-capitalize">
                                      {item?.head_notification}
                                    </h6>
                                  </div>
                                  <div>
                                    <h6 className="font-weight-light">
                                      {item?.description_notification}
                                    </h6>
                                  </div>
                                </div>
                                <div className="d-block align-items-center">
                                  <Badge
                                    pill
                                    // color="danger" //{item.is_read ? "#FFFFFF" : "#f1f8ff"}
                                    style={{
                                      color: "#7268E8",
                                      backgroundColor: "#EEEDFC",
                                      fontSize: 12,
                                      marginBottom: "0.5rem",
                                    }}
                                    className="d-block"
                                  >
                                    {findNotifDate(item.created_at)}
                                  </Badge>
                                </div>
                              </div>
                            </div>
                          );
                        default:
                          return (
                            <div
                              key={index}
                              className="d-flex px-1 py-1 border-bottom"
                              style={{
                                backgroundColor: item.is_read
                                  ? "#FFFFFF"
                                  : "#f1f8ff",
                              }}
                              onClick={() => {
                                this.GotoParams(item, item.id);
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#E5EEF4",
                                  borderRadius: "30px",
                                  padding: "20px",
                                  width: "36px",
                                  height: "36px",
                                }}
                              >
                                <div className="h-100 row d-flex justify-content-center align-content-center">
                                  <img
                                    src={SERVICEIMAGE + item?.user_image}
                                    alt="pic"
                                    width={18}
                                    height={18}
                                    onError={(e) =>
                                      (e.currentTarget.src = DefaultProfile)
                                    }
                                  />
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "15rem 1fr 0.5fr",
                                }}
                              >
                                <div className="ml-1 d-block align-items-center">
                                  <div>
                                    <h6 className="font-weight-bolder text-capitalize">
                                      {item?.value_2}
                                    </h6>
                                  </div>
                                  <div>
                                    <h6 className="font-weight-light">
                                      {item?.description}
                                    </h6>
                                  </div>
                                </div>
                                <div className="d-block align-items-center">
                                  <Badge
                                    pill
                                    // color="danger" //{item.is_read ? "#FFFFFF" : "#f1f8ff"}
                                    style={{
                                      color: "#7268E8",
                                      backgroundColor: "#EEEDFC",
                                      fontSize: 12,
                                      marginBottom: "0.5rem",
                                    }}
                                    className="d-block"
                                  >
                                    {findNotifDate(item.created_at)}
                                  </Badge>
                                </div>
                              </div>
                            </div>
                          );
                      }
                    case "customer":
                      switch (item.sub_status) {
                        case "move":
                          return (
                            <div
                              key={index}
                              className="d-flex px-1 py-1 border-bottom"
                              style={{
                                backgroundColor: item.is_read
                                  ? "#FFFFFF"
                                  : "#f1f8ff",
                              }}
                              onClick={() => {
                                this.GotoParams(item, item.id);
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#E5EEF4",
                                  borderRadius: "30px",
                                  padding: "20px",
                                  width: "36px",
                                  height: "36px",
                                }}
                              >
                                <div className="h-100 row d-flex justify-content-center align-content-center">
                                  <img
                                    src={LoanRejected}
                                    alt="pic"
                                    width={40}
                                    height={40}
                                    onError={(e) =>
                                      (e.currentTarget.src = DefaultProfile)
                                    }
                                  />
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "15rem 1fr 0.5fr",
                                }}
                              >
                                <div className="ml-1 d-block align-items-center">
                                  <div>
                                    <h6 className="font-weight-bolder text-capitalize">
                                      {item?.head_notification}
                                    </h6>
                                  </div>
                                  <div>
                                    <h6 className="font-weight-light">
                                      {item?.description_notification}
                                    </h6>
                                  </div>
                                </div>
                                <div className="d-block align-items-center">
                                  <Badge
                                    pill
                                    // color="danger" //{item.is_read ? "#FFFFFF" : "#f1f8ff"}
                                    style={{
                                      color: "#7268E8",
                                      backgroundColor: "#EEEDFC",
                                      fontSize: 12,
                                      marginBottom: "0.5rem",
                                    }}
                                    className="d-block"
                                  >
                                    {findNotifDate(item.created_at)}
                                  </Badge>
                                </div>
                              </div>
                            </div>
                          );
                        default:
                          return (
                            <div
                              key={index}
                              className="d-flex px-1 py-1 border-bottom"
                              style={{
                                backgroundColor: item.is_read
                                  ? "#FFFFFF"
                                  : "#f1f8ff",
                              }}
                              onClick={() => {
                                this.GotoParams(item, item.id);
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#E5EEF4",
                                  borderRadius: "30px",
                                  padding: "20px",
                                  width: "36px",
                                  height: "36px",
                                }}
                              >
                                <div className="h-100 row d-flex justify-content-center align-content-center">
                                  <img
                                    src={SERVICEIMAGE + item?.user_image}
                                    alt="pic"
                                    width={18}
                                    height={18}
                                    onError={(e) =>
                                      (e.currentTarget.src = DefaultProfile)
                                    }
                                  />
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "15rem 1fr 0.5fr",
                                }}
                              >
                                <div className="ml-1 d-block align-items-center">
                                  <div>
                                    <h6 className="font-weight-bolder text-capitalize">
                                      {item?.value_2}
                                    </h6>
                                  </div>
                                  <div>
                                    <h6 className="font-weight-light">
                                      {item?.description}
                                    </h6>
                                  </div>
                                </div>
                                <div className="d-block align-items-center">
                                  <Badge
                                    pill
                                    // color="danger" //{item.is_read ? "#FFFFFF" : "#f1f8ff"}
                                    style={{
                                      color: "#7268E8",
                                      backgroundColor: "#EEEDFC",
                                      fontSize: 12,
                                      marginBottom: "0.5rem",
                                    }}
                                    className="d-block"
                                  >
                                    {findNotifDate(item.created_at)}
                                  </Badge>
                                </div>
                              </div>
                            </div>
                          );
                      }
                    default:
                      return true;
                  }
                })
              ) : (
                <div>
                  <div className="text-center pt-2 ">
                    <div className="d-block">
                      <Icon.Bell size={40} />
                    </div>

                    <div className="d-inline">
                      <h6 className="mt-1">No notifications</h6>
                    </div>
                  </div>
                </div>
              )}
            </PerfectScrollbar>
            {this?.state?.notification.length > 0 && (
              <div className="pl-1 pr-1 pt-1">
                <Button
                  color={
                    this.state.notification.length > 0 ? `primary` : `secondary`
                  }
                  disabled={this.state.notification.length > 0 ? false : true}
                  onClick={() => this.ReadAll()}
                  className="btn-block"
                >
                  Read all notifications
                </Button>
              </div>
            )}
          </DropdownMenu>
        </UncontrolledDropdown>

        <UncontrolledDropdown
          tag="li"
          className="dropdown-user nav-item"
          onClick={() => {
            this.setState({ ...this.state, get: true });
          }}
        >
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">
                {this.state.first_name}{" "}
                {this.state.last_name ? this.state.last_name : ""}
              </span>
              <span className="user-status">{this.state.roleUser}</span>
            </div>
            <span data-tour="user">
              <ImageUser
                datas={{
                  user: {
                    image: this.state.image,
                    first_name: this.state.first_name,
                    last_name: this.state.last_name,
                  },
                }}
                width={40}
                height={40}
              />
            </span>
          </DropdownToggle>
          <UserDropdown {...this.state} />
        </UncontrolledDropdown>
      </ul>
    );
  }
}
export default NavbarUser;
