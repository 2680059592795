export default function findNotifDate(date_notified) {
  /**
   * @ findNotifDate : Finds the Date Difference of a Notification
   * @ date_notified : The notification date
   **/
  const date_sent_tmp = new Date(date_notified);
  let date_sent;
  let days_passed;
  let months_passed;
  let years_passed;

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const formatDate = (date) => {
    let d = new Date(date);
    return `${d.getDate()} ${monthNames[d.getMonth()]}`;
  };

  //Check for timestamps
  if (date_notified.indexOf("-") !== -1) {
    date_sent = date_sent_tmp.getTime();
  } else {
    date_sent = date_notified;
  }

  const date_now = new Date();
  //current timestamp
  let today = date_now.getTime();

  //Subtract the timestamps
  let calc = new Date(today - date_sent);

  //Prevent Extra 1 Hour
  calc.setHours(calc.getUTCHours() + 0);

  //Make our result readable
  let calcDate =
    calc.getDate() + "-" + (calc.getMonth() + 1) + "-" + calc.getFullYear();
  let calcTime =
    calc.getHours() + ":" + calc.getMinutes() + ":" + calc.getSeconds();

  //Get How many days, months and years that has passed
  let date_passed = calcDate.split("-");
  let time_passed = calcTime.split(":");

  if (!date_passed.includes("1-1-1970")) {
    days_passed =
      parseInt(date_passed[0]) - 1 !== 0 ? parseInt(date_passed[0]) - 1 : null;
    months_passed =
      parseInt(date_passed[1]) - 1 !== 0 ? parseInt(date_passed[1]) - 1 : null;
    years_passed =
      parseInt(date_passed[2]) - 1970 !== 0
        ? parseInt(date_passed[2]) - 1970
        : null;
  } else {
    days_passed = null;
    months_passed = null;
    years_passed = null;
  }

  let hours_passed = parseInt(time_passed[0]);
  let mins_passed = parseInt(time_passed[1]);
  let secs_passed = parseInt(time_passed[2]);

  //Set up your Custom Text output here
  const s = ["sec ago", "secs ago"]; //seconds
  const m = ["min", "sec ago", "mins", "secs ago"]; //minutes
  const h = ["hr", "min ago", "hrs", "mins ago"]; //hours
  const ago = ["ago"];

  let ret, retA, retB;

  // Hari belum terlewati, Bulan belum terlewati, Tahun belum terlewati, Jam belum terlewati, Menit belum terlewati.
  if (
    !days_passed &&
    !months_passed &&
    !years_passed &&
    !hours_passed &&
    !mins_passed
  ) {
    ret =
      secs_passed === 1 ? secs_passed + " " + s[0] : secs_passed + " " + s[1];
    // Hari belum terlewati, Bulan belum terlewati, Tahun belum terlewati, Jam belum terlewati.
  } else if (!days_passed && !months_passed && !years_passed && !hours_passed) {
    retA =
      mins_passed === 1 ? mins_passed + " " + m[0] : mins_passed + " " + m[2];
    retB =
      secs_passed === 1 ? secs_passed + " " + m[1] : secs_passed + " " + m[3];

    ret = retA + " " + ago[0];
    // Hari belum terlewati, Bulan belum terlewati, Tahun belum terlewati.
  } else if (!days_passed && !months_passed && !years_passed) {
    retA =
      hours_passed === 1
        ? hours_passed + " " + h[0]
        : hours_passed + " " + h[2];
    retB =
      mins_passed === 1 ? mins_passed + " " + h[1] : mins_passed + " " + h[3];

    ret = retA + " " + ago[0];
    // Tahun belum terlewati, Bulan belum terlewati.
  } else if (!years_passed && !months_passed) {
    retA = days_passed === 1 ? "Yesterday" : formatDate(date_notified);
    ret = retA;
    // Tahun belum terlewati.
  } else if (!years_passed) {
    // days_passed = 1;
    retA = formatDate(date_notified);
    ret = retA;
    // Semuanya sudah terlewati
  } else {
    retA = formatDate(date_notified);
    retB = date_sent_tmp.getFullYear();

    ret = retA + " " + retB;
  }

  //Check if return contains a negative value
  if (ret.includes("-")) {
    ret += " ( TIME ERROR )-> Invalid Date Provided!";
  }

  return ret;
}
